import { ErrorAndDescriptionRow } from '@ui-components/common/ErrorAndDescriptionRow'
import { IndentedFormFieldContainer } from '@ui-components/global/FormStyling'
import { QuaternaryHeading } from '@ui-components/typography/headings/QuaternaryHeading'
import { TertiaryHeading } from '@ui-components/typography/headings/TertiaryHeading'
import { AdditionalText, BodyText, LabelText } from '@ui-components/typography/Typography'
import { AriaRole, ReactElement, ReactNode } from 'react'
import { styled } from 'styled-components'

import { ColoredLabel, ColoredLabelProps } from '../dataDisplay/ColoredLabel'
import { LoadingSpinner } from '../decorative/LoadingSpinner'
import { BREAKPOINT_LARGE_DESKTOP_PX, COMMON_PLACEHOLDER } from '../global/constants'
import { SPACE_SMALL, SPACE_XXXSMALL } from '../global/spacing'
import { TwoColumnLayout } from '../layout/TwoColumnLayout'

export type SummaryLayout = 'full' | 'concise'

// After react 18 upgrade we could set id optional and use useId()
// https://reactjs.org/docs/hooks-reference.html#useid
interface DataSummaryItemProps {
  coloredLabel?: ColoredLabelProps
  testId?: string
  id: string
  label: string
  description?: string
  value: string | ReactNode
  children?: ReactNode
  mode?: SummaryLayout
  labelId?: string
  async?: {
    isLoading: boolean
    loadingText: string
  }
  role?: AriaRole
  error?: string
  ['aria-labelledby']?: string
}

const SummaryItemContainer = styled.div`
  overflow-wrap: break-word;
`

const LabelSection = ({ label, description, id }: { label: string; description?: string; id: string }): JSX.Element => {
  return (
    <>
      <LabelText tagName="div" id={`${id}-label`}>
        {label}
      </LabelText>
      {description && <AdditionalText tagName="div">{description}</AdditionalText>}
    </>
  )
}

const DataSummaryItemUnstyled = ({
  id,
  label,
  labelId,
  description,
  testId,
  value,
  coloredLabel,
  children,
  async,
  mode = 'full',
  error,
  ...rest
}: DataSummaryItemProps) => {
  const isFullMode = mode === 'full'

  let renderedValue: ReactNode | string
  if (async?.isLoading) {
    renderedValue = <LoadingSpinner size="small" alt={async.loadingText} />
  } else {
    renderedValue = typeof value !== 'undefined' && value !== '' && value !== null ? value : COMMON_PLACEHOLDER
  }

  const mainId = labelId ?? id

  return (
    <SummaryItemContainer data-testid={testId ?? label} {...rest}>
      {coloredLabel && <ColoredLabel color={coloredLabel.color} text={coloredLabel.text} />}
      {isFullMode && <LabelSection id={mainId} label={label} description={description} />}
      <div style={{ minHeight: '25px' }}>
        <BodyText>{renderedValue}</BodyText>
        {error && <ErrorAndDescriptionRow id={mainId} error={error} />}
      </div>
      {children && <IndentedFormFieldContainer>{children}</IndentedFormFieldContainer>}
    </SummaryItemContainer>
  )
}

export const DataSummaryItem = styled(DataSummaryItemUnstyled)``

export const DataSummarySubheading = styled(QuaternaryHeading)``

interface DataSummaryProps {
  children:
    | ReactElement<typeof DataSummaryItem>
    | ReactElement<typeof DataSummaryItem>[]
    | ReactElement<typeof DataSummarySubheading>
    | ReactElement<typeof DataSummarySubheading>[]
    | ReactNode
  heading?: string
  editLink?: ReactNode
  className?: string
  largeScreenSupport?: boolean
  headingTagName?: 'h2' | 'h3'
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const StyledTertiaryHeading = styled(TertiaryHeading)`
  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    margin-bottom: ${SPACE_XXXSMALL};
  }
`

const EditLinkContainer = styled.div`
  justify-self: end;
`

const LegacyEditLinkContainer = styled.div`
  float: right;
  margin-top: -7px;
`

const DataContainer = styled.div`
  flex-direction: column;

  & > * {
    padding-top: ${SPACE_SMALL};
    margin-bottom: ${SPACE_SMALL};

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const DataSummaryUnstyled = ({
  children,
  className,
  heading,
  editLink = null,
  largeScreenSupport = false,
  headingTagName,
}: DataSummaryProps) => {
  return (
    <div className={className}>
      {largeScreenSupport ? (
        <TwoColumnLayout
          leftContent={
            <HeaderContainer>
              {heading && <StyledTertiaryHeading header={heading} tagName={headingTagName} />}
              {editLink && <EditLinkContainer>{editLink}</EditLinkContainer>}
            </HeaderContainer>
          }
          rightContent={<DataContainer>{children}</DataContainer>}
        />
      ) : (
        <>
          {editLink && <LegacyEditLinkContainer>{editLink}</LegacyEditLinkContainer>}
          {heading && <TertiaryHeading header={heading} tagName={headingTagName} />}
          {children}
        </>
      )}
    </div>
  )
}

// Prefer StepSummaryView
export const DataSummary = styled(DataSummaryUnstyled)`
  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`
