import {
  RadioButton,
  RadioButtonProps as DSRadioButtonProps,
} from '@s-group/design-system-components/RadioButton/RadioButton'
import classNames from 'classnames'
import { ComponentType, FocusEvent, forwardRef, MouseEvent, RefAttributes } from 'react'
import { styled } from 'styled-components'

import { Label } from '../../dataDisplay/Label'
import { Colors } from '../../global/colors'
import { FormRadioFieldProps } from '../../global/ComponentProps'
import { HORIZONTAL_PADDING_DEFAULT, VERTICAL_PADDING_DEFAULT } from '../../global/constants'

const CenteredFlexContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;

  > label {
    padding: ${VERTICAL_PADDING_DEFAULT}px ${HORIZONTAL_PADDING_DEFAULT}px;
  }

  label {
    cursor: pointer;
  }

  &:hover:not(:disabled) ~ .visible-radio {
    background-color: ${Colors.BackgroundWeakNeutral};
  }
`

const TypedDSRadioButton = RadioButton as ComponentType<DSRadioButtonProps & RefAttributes<HTMLInputElement>>

const StyledRadioButton = styled(TypedDSRadioButton)`
  flex-flow: row-reverse;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  && svg {
    margin: 0 -0.1875rem 0 0.3125rem;
  }
`

const isTextSelected = () => {
  const selection = window.getSelection()
  return selection && selection.toString().length > 0
}

const handleOnMouseDown = (e: MouseEvent) => {
  /**
   * In tests userEvent.click() seems to not focus the clicked element if preventDefault is called,
   * and it causes some tests to fail. It seems work correctly in real browser environment. Se let's disable this in tests.
   */
  if (process.env.NODE_ENV !== 'test') {
    /**
     * Use preventDefault to avoid triggering form field validation when clicking radio button
     * after it has been automatically focused in a dynamic form field. When clicking radio button
     * the form fields loses focus (label element wraps the clickable area) for the time mouse is
     * being pressed and this causes error to flash before one radio button is selected when mouse is released.
     *
     * e.preventDefault() doesn't allow users to select radio button if they happen to have some text selected on the page.
     * To avoid this issue we need to first check if users has text selected before calling preventDefault.
     */
    if (!isTextSelected()) {
      e.preventDefault()
    }
  }
}

export const Radio = forwardRef<HTMLInputElement, FormRadioFieldProps>(
  ({ id = '', name, className, disabled, onBlur, value, description, label, onChange, error }, ref) => {
    const overriddenId = `${id}-radio-option-${value}`.replace(/\s+/g, '')

    return (
      <CenteredFlexContainer>
        <StyledRadioButton
          onChange={onChange}
          alternative={true}
          ref={ref}
          name={name}
          id={overriddenId}
          className={classNames(className, { error: !!error })}
          disabled={disabled}
          onBlur={(e: FocusEvent) => {
            if (onBlur) {
              onBlur(e)
            }
          }}
          value={value}
          labelProps={{
            onMouseDown: (e) => handleOnMouseDown(e),
          }}
        >
          {label && <Label label={label} id={overriddenId} description={description} as={'span'} useBodyText={true} />}
        </StyledRadioButton>
      </CenteredFlexContainer>
    )
  },
)

Radio.displayName = 'Radio'
