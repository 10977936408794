import classNames from 'classnames'
import { forwardRef } from 'react'
import { styled } from 'styled-components'

import { ErrorAndDescriptionRow } from '../common/ErrorAndDescriptionRow'
import { Label } from '../dataDisplay/Label'
import { Colors } from '../global/colors'
import { InputFieldWrapper } from '../global/CommonStyling'
import {
  CheckboxProps,
  FormLabelledCheckBoxProps,
  setFontThemeCss,
  ThemedComponentProps,
} from '../global/ComponentProps'
import {
  BREAKPOINT_LARGE_DESKTOP_PX,
  HORIZONTAL_PADDING_DEFAULT,
  LARGE_COINTAINER_MAX_WIDTH_PX,
  VERTICAL_PADDING_DEFAULT,
} from '../global/constants'
import { buildAriaAttributes } from '../global/global'
import { Checkbox } from './primitive/Checkbox'

const CheckboxWrapperLabel = styled.div<ThemedComponentProps>`
  color: ${Colors.TextDefaultNeutral};
  ${(props: ThemedComponentProps) => props.$theme && setFontThemeCss(props.$theme)};

  &.disabled {
    color: ${Colors.ElementMediumNeutral};
    * {
      color: ${Colors.ElementMediumNeutral};
    }
  }
`

const LabelWrapper = styled.label<FormLabelledCheckBoxProps>`
  flex-grow: 1;

  .disabled {
    color: ${Colors.ElementMediumNeutral};
  }
  cursor: pointer;

  ${(props: Partial<FormLabelledCheckBoxProps>) =>
    props.$isGrouped &&
    `
    padding: ${VERTICAL_PADDING_DEFAULT}px ${HORIZONTAL_PADDING_DEFAULT}px;
  `}
`

const CUSTOM_VERTICAL_PADDING_FOR_GROUPED_CHECKBOXES = VERTICAL_PADDING_DEFAULT + 2
const CUSTOM_HORIZONTAL_PADDING_FOR_GROUPED_CHECKBOXES = HORIZONTAL_PADDING_DEFAULT + 2

const InputFieldFlexWrapper = styled(InputFieldWrapper)<FormLabelledCheckBoxProps>`
  display: flex;
  ${(props: Partial<FormLabelledCheckBoxProps>) =>
    props.$isGrouped &&
    `> label {
      padding: ${CUSTOM_VERTICAL_PADDING_FOR_GROUPED_CHECKBOXES}px ${CUSTOM_HORIZONTAL_PADDING_FOR_GROUPED_CHECKBOXES}px;
    }
  `}
  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    max-width: ${(props) => (props.$isOneColumnLayout ? `${LARGE_COINTAINER_MAX_WIDTH_PX}px` : undefined)};
  }
`

const BorderedWrapper = styled(InputFieldFlexWrapper)`
  margin-top: 3px;
  border-radius: 8px;
  border: 1px solid ${Colors.BorderWeakNeutral};
  overflow: hidden;

  > :hover:not(.disabled) {
    background-color: ${Colors.BackgroundWeakNeutral};
  }

  align-items: center;

  &.error {
    border-color: ${Colors.BorderError};
  }

  > :first-child {
    padding: ${VERTICAL_PADDING_DEFAULT}px ${HORIZONTAL_PADDING_DEFAULT}px;
    > :first-child {
      align-items: center;
    }
  }
`

const CenteredFlexContainer = styled.div<FormLabelledCheckBoxProps>`
  display: flex;
  ${(props: Partial<FormLabelledCheckBoxProps>) =>
    props.$shouldCenterItemsVertically &&
    `
     > * {
        align-items: center;
      }
  `}
`

const RightSidedFlexContainer = styled(CenteredFlexContainer)`
  justify-content: space-between;
`

const LeftSidedFlexContainer = styled(CenteredFlexContainer)`
  > :last-child {
    padding-left: ${HORIZONTAL_PADDING_DEFAULT}px;
  }
`

export const FormLabelledCheckbox = forwardRef<HTMLInputElement, FormLabelledCheckBoxProps>((props, ref) => {
  const {
    id,
    required,
    description,
    side = 'right',
    border = true,
    label,
    error,
    $shouldCenterItemsVertically = border,
    $isGrouped = false,
    isOneColumnLayout,
    groupId,
  } = props

  const { className, ...checkboxProps } = props

  const hasDescription = !!description
  const hasError = !!error

  const checkbox = (
    <Checkbox
      key={`${id}-checkbox`}
      ref={ref}
      {...(checkboxProps as CheckboxProps)}
      id={id}
      error={error}
      {...buildAriaAttributes(id, !!required, hasDescription, hasError, groupId ? `${groupId}-error` : undefined)}
    />
  )
  const FlexContainer = side === 'left' ? LeftSidedFlexContainer : RightSidedFlexContainer

  const labelComponent = (
    <CheckboxWrapperLabel key={`${id}-labelwrapper`} className={classNames({ disabled: props.disabled })}>
      {label && <Label id={id} label={label} description={description} isRequired={required} useBodyText={true} />}
    </CheckboxWrapperLabel>
  )
  const components = [checkbox, labelComponent]

  const LabeledCheckbox = (
    <LabelWrapper
      id={`${id}-descriptors`}
      key={`${id}-descriptors`}
      name={`${id}-descriptors`}
      htmlFor={id}
      className={classNames({ disabled: props.disabled })}
      $isGrouped={$isGrouped}
    >
      <FlexContainer
        id={`${id}-descriptors`}
        name={`${id}-descriptors`}
        $shouldCenterItemsVertically={$shouldCenterItemsVertically}
      >
        {side === 'left' ? components : [...components].reverse()}
      </FlexContainer>
    </LabelWrapper>
  )

  const ForCheckBoxWrapper = border ? BorderedWrapper : InputFieldFlexWrapper

  return (
    <div className={className}>
      {hasError && <ErrorAndDescriptionRow id={id} error={error} />}
      <ForCheckBoxWrapper
        id={`${id}-descriptors`}
        name={`${id}-descriptors`}
        className={classNames({ error: hasError })}
        $isGrouped={$isGrouped}
        $isOneColumnLayout={isOneColumnLayout}
      >
        {LabeledCheckbox}
      </ForCheckBoxWrapper>
    </div>
  )
})

FormLabelledCheckbox.displayName = 'FormLabelledCheckbox'
