import { Endpoint, VerifyAuthCodeResponse } from '../types'
import {
  LoanApplicationResultResponse,
  SecLoansApplicationMetadata,
  SecLoansCreateApplicationRequest,
  SecLoansCreateApplicationResponse,
  SecLoansGetApplicationResponse,
  SecLoansUpdateApplicationRequest,
  SecLoansUpdateApplicationResponse,
  SecLoansUserInfoResponse,
  SecLoansVerifyAuthCodeRequest,
} from './types'

export const ENDPOINT_GET_USER_INFO: Endpoint<SecLoansUserInfoResponse> = {
  path: '/user-info',
  method: 'get',
}

export const ENDPOINT_CREATE_LOAN_APPLICATION: Endpoint<
  SecLoansCreateApplicationResponse,
  SecLoansCreateApplicationRequest
> = {
  path: '/loan-application',
  method: 'post',
}

export const ENDPOINT_GET_LOAN_APPLICATIONS: Endpoint<SecLoansApplicationMetadata[]> = {
  path: '/loan-applications',
  method: 'get',
}

export const ENDPOINT_GET_LOAN_APPLICATION: Endpoint<SecLoansGetApplicationResponse, void, never, 'applicationId'> = {
  path: '/loan-application/{applicationId}',
  method: 'get',
}

export const ENDPOINT_GET_LOAN_APPLICATION_PDF: Endpoint<string, void, never, 'applicationId'> = {
  path: '/loan-application/{applicationId}/pdf',
  method: 'get',
}

export const ENDPOINT_UPDATE_LOAN_APPLICATION: Endpoint<
  SecLoansUpdateApplicationResponse,
  SecLoansUpdateApplicationRequest,
  never,
  'applicationId'
> = {
  path: '/loan-application/{applicationId}',
  method: 'put',
}

export const ENDPOINT_LOAN_APPLICATION_ACTION: Endpoint<void, void, never, 'applicationId' | 'action'> = {
  path: '/loan-application/{applicationId}/action/{action}',
  method: 'put',
}

export const ENDPOINT_VERIFY_AUTH_CODE: Endpoint<VerifyAuthCodeResponse, SecLoansVerifyAuthCodeRequest> = {
  path: '/verify-auth-code',
  method: 'post',
}

export const ENDPOINT_GET_LOAN_APPLICATION_RESULT: Endpoint<LoanApplicationResultResponse> = {
  path: '/loan-application/result',
  method: 'get',
}
