import { Button, ButtonProps } from '@ui-components/actions/Button'
import { MediumSectionContent } from '@ui-components/containers/MediumSectionContent'
import { Notification, NotificationProperties } from '@ui-components/containers/Notification'
import { TightSectionContent } from '@ui-components/containers/TightSectionContent'
import { AdditionalText } from '@ui-components/typography/Typography'
import { ReactNode } from 'react'

import { PrimaryHeading } from '../typography/headings/PrimaryHeading'
import { SecondaryHeading } from '../typography/headings/SecondaryHeading'

export interface IntroSectionProps {
  headingLevel?: 1 | 2
  title: string
  leadText?: ReactNode
  additionalText?: ReactNode
  moreInfoButtonProps?: ButtonProps
  notificationProps?: NotificationProperties
  progressText?: string
}

export const IntroSection = ({
  headingLevel = 2,
  title,
  leadText,
  additionalText,
  moreInfoButtonProps,
  notificationProps,
  progressText,
}: IntroSectionProps): JSX.Element => {
  const HeadingComponent = headingLevel === 1 ? PrimaryHeading : SecondaryHeading

  return (
    <div>
      <TightSectionContent>
        {notificationProps && <Notification status="info" {...notificationProps} />}
        {progressText && (
          <AdditionalText aria-label={progressText} tagName="div">
            {progressText}
          </AdditionalText>
        )}
      </TightSectionContent>
      <MediumSectionContent>
        <HeadingComponent header={title} leadText={leadText} additionalText={additionalText} />
        {moreInfoButtonProps && <Button variant="plain" compact={true} {...moreInfoButtonProps} />}
      </MediumSectionContent>
    </div>
  )
}
