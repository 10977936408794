import { QuaternaryHeading } from '@ui-components/typography/headings/QuaternaryHeading'
import { TertiaryHeading } from '@ui-components/typography/headings/TertiaryHeading'
import { Fragment, PropsWithChildren, ReactNode, useId } from 'react'
import { styled } from 'styled-components'

import { EditItemButton } from '../actions/special/EditItemButton'
import { EditLinkButton } from '../actions/special/EditLinkButton'
import { RemoveItemButton } from '../actions/special/RemoveItemButton'
import { ActionProperties } from '../actions/types'
import { ColoredLabel, ColoredLabelProps } from '../dataDisplay/ColoredLabel'
import { MediumErrorLabel } from '../global/CommonStyling'
import { BREAKPOINT_LARGE_DESKTOP_PX } from '../global/constants'
import { SPACE_MEDIUM, SPACE_XSMALL, SPACE_XXSMALL, SPACE_XXXXSMALL } from '../global/spacing'
import { TwoColumnLayout } from '../layout/TwoColumnLayout'
import { parseComponentId } from '../utilities/helpers'
import { getActionTargetAriaLabel } from '../utilities/textHelpers'
import { DataSummaryItem, SummaryLayout } from './DataSummary'

export interface SummaryData {
  key?: string
  testId?: string
  heading: string
  value: ReactNode
  condition?: boolean
  childData?: SummaryData[]
  coloredLabel?: ColoredLabelProps
  sectionHeading?: string
  error?: string
}

export interface StepSummary {
  heading: string
  error?: string
  data: SummaryData[]
  coloredLabel?: ColoredLabelProps
  coloredLabelForItems?: ColoredLabelProps
  editItemProperties?: ActionProperties
  removeItemProperties?: ActionProperties
}

export interface StepSummaryProps extends StepSummary {
  actionMode?: 'link' | 'button'
  mode?: SummaryLayout
  id?: string
  isLabelUnique?: boolean // uses aria-labelledby header if set true, label must be unique not to cause e2e axe failure
  twoColumnLayoutSupport?: boolean
}

export interface StepSummaryItemProps {
  data: SummaryData[]
  coloredLabel?: ColoredLabelProps
  mode?: SummaryLayout
}

const MainContent = styled.section<{ mode: SummaryLayout }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.mode === 'full' ? SPACE_MEDIUM : SPACE_XSMALL)};
`

const ItemContainer = styled.div<{ mode: SummaryLayout }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.mode === 'full' ? SPACE_MEDIUM : SPACE_XXXXSMALL)};
`

const HeaderContainer = styled.div<{ $twoColumnLayoutSupport: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ $twoColumnLayoutSupport }) =>
    $twoColumnLayoutSupport &&
    `
  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: ${SPACE_XXXXSMALL};
  }`}
`

const SectionHeadingContainer = styled(QuaternaryHeading)`
  padding-top: 32px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${SPACE_XXSMALL};
`

const SummaryItemContainer = styled(ItemContainer)`
  > :first-child {
    padding-top: 0;
  }
`

const SimpleSummaryData = (props: StepSummaryItemProps) => {
  const { data, coloredLabel, mode } = props
  const ariaLabelledByPrefix = useId()
  const itemMode = mode ?? 'full'

  return (
    <SummaryItemContainer mode={itemMode}>
      {data
        ?.filter((item) => shouldShowItem(item))
        .map((summaryItem, index) => {
          const {
            heading: header,
            testId,
            value,
            childData,
            coloredLabel: itemColoredLabel,
            sectionHeading,
            key: keyCandidate,
            error,
          } = summaryItem
          const key = keyCandidate ?? header.replace(/\s/g, '_')
          const ariaLabelledBy = `${ariaLabelledByPrefix}-${key}-${index}-id`

          return (
            <Fragment key={`${key}-wrapper`}>
              {sectionHeading && (
                <SectionHeadingContainer
                  header={sectionHeading}
                  tagName="h3"
                  data-testid="section-header"
                  id={ariaLabelledBy}
                />
              )}
              <DataSummaryItem
                coloredLabel={itemColoredLabel ?? coloredLabel}
                key={key}
                id={header}
                label={header}
                labelId={ariaLabelledBy}
                value={value}
                testId={testId}
                mode={itemMode}
                aria-labelledby={mode === 'full' ? `${ariaLabelledBy}-label` : ariaLabelledBy}
                error={error}
              >
                {childData?.some((item) => shouldShowItem(item)) && <SimpleSummaryData data={childData} />}
              </DataSummaryItem>
            </Fragment>
          )
        })}
    </SummaryItemContainer>
  )
}

export const StepSummaryView = (props: PropsWithChildren<StepSummaryProps>) => {
  const {
    heading,
    data,
    coloredLabel,
    coloredLabelForItems,
    removeItemProperties,
    editItemProperties,
    error,
    mode,
    actionMode = 'link',
    children,
    id,
    isLabelUnique,
    twoColumnLayoutSupport = true,
  } = props

  const itemMode = mode ?? 'full'

  const uniqueId = id || parseComponentId(heading)
  const actionsAsButton = actionMode === 'button'

  const header = (
    <HeaderContainer $twoColumnLayoutSupport={twoColumnLayoutSupport}>
      <TertiaryHeading header={heading} data-testid="main-header" id={`step-summary-header-${uniqueId}`} />
      <ButtonContainer>
        {removeItemProperties && actionsAsButton && (
          <RemoveItemButton
            onClick={removeItemProperties.onClick}
            label={removeItemProperties.label}
            aria-label={getActionTargetAriaLabel(removeItemProperties.label, heading)}
          />
        )}
        {editItemProperties && actionsAsButton && (
          <EditItemButton
            onClick={editItemProperties.onClick}
            label={editItemProperties.label}
            aria-label={getActionTargetAriaLabel(editItemProperties.label, heading)}
            compact={true}
          />
        )}
        {editItemProperties && !actionsAsButton && (
          <EditLinkButton
            aria-label={getActionTargetAriaLabel(editItemProperties.label, heading)}
            onClick={editItemProperties.onClick}
            label={editItemProperties.label}
          />
        )}
      </ButtonContainer>
    </HeaderContainer>
  )

  const content = (
    <>
      {coloredLabel && <ColoredLabel {...coloredLabel} />}
      {error && <MediumErrorLabel>{error}</MediumErrorLabel>}
      <SimpleSummaryData data={data} coloredLabel={coloredLabelForItems} mode={itemMode} />
      {children}
    </>
  )

  return (
    <MainContent
      data-testid={`step-summary-${heading}`}
      mode={itemMode}
      id={id}
      tabIndex={-1}
      {...(isLabelUnique ? { 'aria-labelledby': `step-summary-header-${uniqueId}` } : {})}
    >
      {twoColumnLayoutSupport ? (
        <TwoColumnLayout leftContent={header} rightContent={content} />
      ) : (
        <>
          {header}
          {content}
        </>
      )}
    </MainContent>
  )
}

const shouldShowItem = (item: SummaryData): boolean => {
  return item.condition === undefined || item.condition === true
}
