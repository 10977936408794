import { FlexColumnContainer } from '@ui-components/global/CommonStyling'
import { SPACE_XXXSMALL } from '@ui-components/global/spacing'
import { styled } from 'styled-components'

/**
 * Container with tightest spacing, with 0.25rem (4px) gap between elements
 */
export const ExtraTightSectionContent = styled(FlexColumnContainer)`
  padding: 0;
  gap: ${SPACE_XXXSMALL};
  margin-bottom: 0px;
`
