import { PenIcon } from '../../assets/Icons'
import { useIsDesktopScreen } from '../../hooks/useIsDesktopScreen'
import { LinkButton, LinkButtonProps } from '../LinkButton'

export type EditLinkButtonProps = Required<Pick<LinkButtonProps, 'aria-label' | 'label' | 'onClick'>>

export const EditLinkButton = ({ 'aria-label': ariaLabel, label, onClick }: EditLinkButtonProps) => {
  const isDesktop = useIsDesktopScreen()

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // Prevent default navigation behaviour
    e.preventDefault()
    onClick?.(e)
  }

  return (
    <LinkButton
      aria-label={ariaLabel}
      compact={true}
      icon={<PenIcon />}
      label={isDesktop ? label : undefined}
      onClick={handleClick}
      variant="plain"
    />
  )
}
