import { styled } from 'styled-components'

import { LegacyBrandProps } from '../common/types'
import { SegmentedProgressBar } from '../decorative/SegmentedProgressBar'
import { SecondaryHeaderWrapper } from '../global/CommonStyling'
import { LogoIconType } from '../global/ComponentProps'
import { PrimaryTopBar, PrimaryTopBarVariantOptions } from '../navigation/PrimaryTopBar'
import { SecondaryTopBar } from '../navigation/SecondaryTopBar'
import { PAGE_EXIT_BUTTON_CLASS } from '../utilities/helpers'
import { WizardHeaderButtonProps, WizardPageSubHeaderProps } from './WizardTypes'

interface WizardHeaderProps extends LegacyBrandProps {
  shouldShowMainHeader: boolean
  title?: string
  icon: LogoIconType
  headerButtonProps?: WizardHeaderButtonProps
  subHeaderOptions?: WizardPageSubHeaderProps
  fixToTop?: boolean
  additionalText?: string
  hideBoxShadow?: boolean
  transparentTopBar?: boolean
  topBarVariant?: PrimaryTopBarVariantOptions
}

const Header = styled.header`
  width: 100%;
`

export const WizardHeader = (props: WizardHeaderProps) => {
  const {
    subHeaderOptions,
    fixToTop,
    additionalText,
    hideBoxShadow,
    transparentTopBar,
    topBarVariant = 'compact',
  } = props
  const progressBarOptions = subHeaderOptions?.progressBarOptions
  const secondaryTopBarMenuProps = subHeaderOptions?.secondaryTopBarMenuProps

  return (
    <Header>
      {props.shouldShowMainHeader && (
        <PrimaryTopBar
          actionButtonOptions={{
            className: PAGE_EXIT_BUTTON_CLASS,
            label: props.headerButtonProps?.headerButtonLabel ?? '',
            onClick: props.headerButtonProps?.onHeaderButtonClick,
          }}
          icon={props.icon}
          serviceName={props.title}
          userDisplayName={additionalText}
          variant={topBarVariant}
          isTransparent={transparentTopBar}
        />
      )}
      {subHeaderOptions && (
        <SecondaryHeaderWrapper $fixedToTop={!!fixToTop} data-testid="secondary-header">
          <SecondaryTopBar
            title={subHeaderOptions.stepTitle}
            description={subHeaderOptions.stepDescription}
            small={fixToTop}
            previousButtonProps={{ label: subHeaderOptions.backButtonLabel, onClick: subHeaderOptions.onBack }}
            infoButtonProps={{ label: subHeaderOptions.infoButtonLabel, onClick: subHeaderOptions.onInfo }}
            menu={secondaryTopBarMenuProps}
            hideBoxShadow={hideBoxShadow}
          />
          {progressBarOptions && <SegmentedProgressBar {...progressBarOptions} />}
        </SecondaryHeaderWrapper>
      )}
    </Header>
  )
}
