import { FlexColumnContainer } from '@ui-components/global/CommonStyling'
import { AVERAGE_PAGE_COMPONENT_GAP } from '@ui-components/global/constants'
import { styled } from 'styled-components'

/**
 * Container with medium spacing, with 2rem (32px) gap between elements
 */
export const MediumSectionContent = styled(FlexColumnContainer)`
  padding: 0;
  gap: ${AVERAGE_PAGE_COMPONENT_GAP}px;
  margin-bottom: 0px;
`
