import { StorageDataAbbreviation } from '@shared'
import { formatBytes } from '@shared/utils/formatBytes/formatBytes'
import { IndentedFormFieldContainer } from '@ui-components/global/FormStyling'
import { AdditionalText, BodyText } from '@ui-components/typography/Typography'
import { styled } from 'styled-components'

import { Button } from '../actions/Button'
import { TrashIcon } from '../assets/Icons'
import { ProgressBar } from '../decorative/ProgressBar'
import { Colors } from '../global/colors'
import { SPACE_MEDIUM } from '../global/spacing'
import { TruncatedInMiddleSpan } from './primitive/TruncatedInMiddleSpan'

const UploadItemContainer = styled(IndentedFormFieldContainer)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 0 10px;
`

const LeftColumnContainer = styled.div`
  flex-grow: 1;
  overflow: hidden;
`

const TitleText = styled(BodyText)<{ $isValid: boolean }>`
  color: ${(props) => (props.$isValid ? `${Colors.TextStrongPrimary}` : `${Colors.TextStrongNeutral}`)};
`

const ErrorAdditionalText = styled(AdditionalText)`
  color: ${Colors.TextError};
`

const ProgressBarContainer = styled.div`
  height: ${SPACE_MEDIUM};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
`

const ListItem = styled.li`
  list-style-type: none;
`

interface FormUploadItemProps {
  fileName: string
  fileSize: number
  onRemoveButtonClick: () => void
  isValid: boolean
  dataAbbreviation: StorageDataAbbreviation
  progressBarAriaLabel: string
  removeFileAriaLabel: string
  error?: string
  uploadProgress?: number
  isDeleting?: boolean
}

export const FormUploadItem = ({
  fileName,
  fileSize,
  onRemoveButtonClick,
  error,
  uploadProgress = 100,
  isValid,
  dataAbbreviation,
  progressBarAriaLabel,
  removeFileAriaLabel,
  isDeleting,
}: FormUploadItemProps) => {
  const isUploadDone = uploadProgress >= 100

  const resolveStatus = () => {
    if (!isValid) {
      return <ErrorAdditionalText>{error}</ErrorAdditionalText>
    }

    if (isUploadDone) {
      return <AdditionalText>{formatBytes(fileSize, 2, dataAbbreviation)}</AdditionalText>
    }

    return (
      <ProgressBarContainer>
        <ProgressBar progress={uploadProgress} ariaLabel={progressBarAriaLabel} />
      </ProgressBarContainer>
    )
  }

  return (
    <ListItem>
      <UploadItemContainer>
        <LeftColumnContainer>
          <TitleText weight="regular" tagName="div" $isValid={isValid && isUploadDone}>
            <TruncatedInMiddleSpan text={fileName} />
          </TitleText>
          {resolveStatus()}
        </LeftColumnContainer>
        {!isUploadDone ? null : (
          <Button
            tabIndex={isDeleting ? -1 : undefined}
            onClick={onRemoveButtonClick}
            aria-label={removeFileAriaLabel}
            icon={<TrashIcon />}
            variant="secondary"
            color="neutral"
            loadingMode="icon"
            loading={isDeleting}
          />
        )}
      </UploadItemContainer>
    </ListItem>
  )
}
