import { ExtraTightSectionContent } from '@ui-components/containers/ExtraTightSectionContent'
import { ColoredLabel } from '@ui-components/dataDisplay/ColoredLabel'
import { TextBadgeColors } from '@ui-components/global/ComponentProps'

import { AdditionalText } from '../Typography'
import { SmallHeading } from './headingPrimitives'

export interface QuaternaryHeadingProps {
  header: string
  badgeText?: string
  badgeColor?: TextBadgeColors
  id?: string
  tagName?: 'h1' | 'h2' | 'h3'
  additionalText?: string
  className?: string
  'data-testid'?: string
}

export const TertiaryHeading = ({
  header,
  badgeText,
  badgeColor,
  id,
  tagName = 'h3',
  additionalText,
  className,
  'data-testid': dataTestId,
}: QuaternaryHeadingProps) => {
  return (
    <ExtraTightSectionContent className={className}>
      {badgeText && <ColoredLabel text={badgeText} color={badgeColor} />}
      <SmallHeading tagName={tagName} id={id} data-testid={dataTestId}>
        {header}
      </SmallHeading>
      {additionalText && <AdditionalText>{additionalText}</AdditionalText>}
    </ExtraTightSectionContent>
  )
}
