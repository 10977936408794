import { AdditionalText, BodyText, TextProps } from '@ui-components/typography/Typography'
import { createGlobalStyle, css, styled } from 'styled-components'

import { Colors } from './colors'
import { DEFAULT_INPUT_MAX_WIDTH_PX, STEP_HEADER_BOTTOM_MARGIN } from './constants'
import { SPACE_XXXSMALL } from './spacing'

export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const FlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const ConsentContainer = styled(FlexColumnContainer)`
  gap: 50px;
  margin-bottom: 50px;
`

export const LabelWrapper = styled.div`
  width: 100%;
  max-width: 540px;
`

interface InputFieldWrapperProps {
  $width?: string
  $readonly?: boolean
  $disabled?: boolean
}

export const InputFieldWrapper = styled.div<InputFieldWrapperProps>`
  width: ${(props) => props.$width ?? ''};
  opacity: ${(props) => (props.$disabled ? 0.35 : '')};
  @media (max-width: ${DEFAULT_INPUT_MAX_WIDTH_PX + 20}px) {
    max-width: 100%;
  }
`

export const NarrowInputFieldWrapper = styled(InputFieldWrapper)`
  max-width: ${DEFAULT_INPUT_MAX_WIDTH_PX}px;
  @media (max-width: ${DEFAULT_INPUT_MAX_WIDTH_PX + 20}px) {
    max-width: 100%;
  }
`

export const ErrorSpacerWithReservedSpace = styled.div`
  min-height: 23px;
`

export const PaddedDiv = styled.div`
  padding-top: 10px;
`

export const MediumErrorLabel = styled(BodyText).attrs({ tagName: 'div' })`
  color: ${Colors.TextError};
  margin-top: -12px;
  margin-bottom: 24px;
`

export const ErrorLabel = styled(AdditionalText)<TextProps>`
  display: block;
  color: ${Colors.TextError};
  width: 100%;
  margin-top: ${SPACE_XXXSMALL};
`

export const InputFieldPrefix = styled(BodyText)<TextProps>`
  line-height: 20px;
  padding-right: 8px;

  color: ${Colors.TextDefaultNeutral};
  margin: auto 0;
`

export const SecondaryHeaderWrapper = styled.div<{ $fixedToTop: boolean }>`
  ${({ $fixedToTop }) => {
    if ($fixedToTop) {
      return css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        margin-bottom: 53px;
        max-width: 100vw;
      `
    }
  }}
  z-index: 1;
  margin-bottom: ${STEP_HEADER_BOTTOM_MARGIN}px;
`

export const mainContentAttributes = {
  role: 'main',
}

export const GlobalStyle = createGlobalStyle`
  a {
    color: ${Colors.TextStrongPrimary};

    &:hover {
      color: ${Colors.TextStrongestPrimary};
    }

    &:active {
      color: ${Colors.TextMediumPrimary};
    }
  }

  *, a::before, *::after{
    box-sizing: inherit;
  }

  body {
    min-width: 300px;
    background-color: ${Colors.BackgroundWeakNeutral};
    box-sizing: border-box;
    color: ${Colors.TextDefaultNeutral}
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }
`
