import { PaperContainer } from '@ui-components/containers/PaperContainer'
import { TightSectionContent } from '@ui-components/containers/TightSectionContent'
import { FixedButtonSection } from '@ui-components/global/LayoutStyling'
import { BodyText } from '@ui-components/typography/Typography'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

import { PrimaryButton } from '../actions/special/DynamicWidthButtons'
import { BlueInfoIcon } from '../assets/Icons'
import { Divider } from '../decorative/Divider'
import { Colors } from '../global/colors'
import {
  BREAKPOINT_DESKTOP_PX,
  BREAKPOINT_LARGE_DESKTOP_PX,
  CTA_BUTTON_HEIGHT,
  PAGE_COMPONENT_GAP,
  SECTION_HORIZONTAL_PADDING_DEFAULT,
  SECTION_VERTICAL_PADDING_DEFAULT,
} from '../global/constants'
import { LEGACY_SPACE_LARGE, LEGACY_SPACE_XLARGE } from '../global/legacy/spacing'
import { SPACE_SMALL, SPACE_XXXXLARGE } from '../global/spacing'
import { ButtonContainer } from '../layout/ButtonContainer'
import { ConfirmButtonProps, WizardStepContentProps } from '../layout/WizardTypes'

export const DividerContainer = styled.section`
  padding: 0 16px;

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    padding: 0 ${SECTION_HORIZONTAL_PADDING_DEFAULT}px;
  }
`

const FixedConfirmSection = styled(FixedButtonSection)`
  transition: height 200ms;
  box-shadow: 0px 4px 8px 2px rgb(0 0 0 / 10%);
  width: 100vw;
  &.visible {
    height: 56px;
  }
  &.hidden {
    height: 0px;
  }
`

const StandardButtonContainer = styled.div`
  align-self: center;
  max-width: ${BREAKPOINT_DESKTOP_PX - 1}px;
  width: 100%;
  min-height: ${CTA_BUTTON_HEIGHT}px;
`

const CheckInformationSection = styled.div`
  margin-top: -16px;
  background: ${Colors.BackgroundInfo};
  border-top: 1px solid ${Colors.BorderWeakNeutral};
  color: ${Colors.TextInfo};
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  text-align: left;

  & > *:first-child {
    margin-right: 10px;
    flex-shrink: 0;
  }

  p:not(:last-child) {
    margin-bottom: 16px;
  }
`

const FooterContainer = styled.div`
  margin-top: ${LEGACY_SPACE_LARGE};
`

const ConfirmSection = styled(TightSectionContent)<{ $largeScreenSupport: boolean }>`
  margin-top: ${PAGE_COMPONENT_GAP - SECTION_VERTICAL_PADDING_DEFAULT}px;
  padding: 0 ${SPACE_SMALL} ${LEGACY_SPACE_XLARGE};

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    padding: 0 ${SECTION_HORIZONTAL_PADDING_DEFAULT}px ${LEGACY_SPACE_XLARGE};
  }

  ${({ $largeScreenSupport }) =>
    $largeScreenSupport &&
    `
      @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
        padding: 0 ${SPACE_XXXXLARGE} ${LEGACY_SPACE_XLARGE};
      }
  `}
`

interface DisplayedButtonProps {
  confirmButtonProps?: ConfirmButtonProps
  isLoading?: boolean
  disabled?: boolean
  ['aria-disabled']?: boolean
  ['data-testid']: string
}
const DisplayedButton = (props: DisplayedButtonProps) => {
  const { confirmButtonProps, isLoading, disabled, 'data-testid': dataTestId } = props
  let button: ReactNode

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (e: React.BaseSyntheticEvent<object, any, any>) => {
    if (e.currentTarget.ariaDisabled === 'true') {
      return
    }
    confirmButtonProps?.onConfirm()
  }

  if (confirmButtonProps) {
    const buttonLabel = confirmButtonProps.confirmLabel
    const testId = dataTestId + (isLoading ? '-loading' : '')
    button = (
      <PrimaryButton
        label={buttonLabel}
        onClick={handleSubmit}
        data-testid={testId}
        disabled={!!confirmButtonProps.disabled || !!disabled}
        aria-disabled={props['aria-disabled']}
        loading={isLoading}
      />
    )
  }

  return <ButtonContainer>{button}</ButtonContainer>
}

export const WizardPageContent = (props: WizardStepContentProps): JSX.Element | null => {
  const {
    stepId,
    children,
    confirmButtonProps,
    pageMessage,
    isLoading,
    customFooter,
    hideBoxShadow,
    largeScreenSupport = false,
  } = props
  const shouldShowFixedButton = !!confirmButtonProps?.shouldShowConfirmFloating
  const shouldShowStandardButton = !!confirmButtonProps && !shouldShowFixedButton
  const shouldRender = !!(children || pageMessage || confirmButtonProps)
  const fullWidthSupport = props.contentContainerType === 'wide'

  return shouldRender ? (
    <>
      <PaperContainer
        data-testid={stepId}
        $hideBoxShadow={hideBoxShadow}
        $largeScreenSupport={largeScreenSupport}
        $fullWidthSupport={fullWidthSupport}
      >
        {pageMessage && (
          <CheckInformationSection>
            <BlueInfoIcon />
            <BodyText>{pageMessage}</BodyText>
          </CheckInformationSection>
        )}
        {children}
        <FixedConfirmSection
          className={shouldShowFixedButton ? 'visible' : 'hidden'}
          aria-hidden={!shouldShowFixedButton}
        >
          <DisplayedButton
            disabled={!shouldShowFixedButton}
            confirmButtonProps={confirmButtonProps}
            isLoading={isLoading}
            aria-disabled={isLoading}
            data-testid="confirm-page-button-fab"
          />
        </FixedConfirmSection>
        {shouldShowStandardButton && (
          <ConfirmSection $largeScreenSupport={largeScreenSupport}>
            <Divider />
            <StandardButtonContainer>
              <DisplayedButton
                confirmButtonProps={confirmButtonProps}
                isLoading={isLoading}
                aria-disabled={isLoading}
                data-testid="confirm-page-button"
              />
            </StandardButtonContainer>
          </ConfirmSection>
        )}
      </PaperContainer>
      {customFooter && <FooterContainer>{customFooter}</FooterContainer>}
    </>
  ) : null
}
