import { Link } from '@s-group/design-system-components/Link/Link'
import { IconNavigationArrowLeft } from '@s-group/design-system-icons'
import { BodyText } from '@ui-components/typography/Typography'
import { styled } from 'styled-components'

import { FlexColumnContainer } from '../global/CommonStyling'
import { MAIN_STEP_HEADER_HEIGHT } from '../global/constants'

export interface BackActionTopBarProps {
  backLabel: string
  onBackClick: () => void
}

const Container = styled(FlexColumnContainer)`
  width: 100%;
  height: ${MAIN_STEP_HEADER_HEIGHT}px;
  padding: 8px;
  position: relative;

  a {
    width: fit-content;
  }
`

export const BackActionTopBar = (props: BackActionTopBarProps): JSX.Element => {
  const { backLabel, onBackClick } = props

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    onBackClick()
  }

  return (
    <Container as="nav">
      <Link
        href=""
        iconPos="before"
        standalone={true}
        color="neutral"
        icon={[IconNavigationArrowLeft, { size: '24' }]}
        onClick={handleClick}
      >
        <BodyText>{backLabel}</BodyText>
      </Link>
    </Container>
  )
}
