import { MakeRequired } from '@shared'
import { TrashIcon } from '@ui-components/assets/Icons'
import { useIsDesktopScreen } from '@ui-components/hooks/useIsDesktopScreen'

import { Button, ButtonProps } from '../Button'

type SelectedButtonProps = Pick<ButtonProps, 'aria-label' | 'data-testid' | 'label' | 'onClick' | 'type'>
export type RemoveItemButtonProps = MakeRequired<SelectedButtonProps, 'aria-label' | 'label'>

export const RemoveItemButton = ({ label, ...rest }: RemoveItemButtonProps) => {
  const isDesktop = useIsDesktopScreen()
  return <Button icon={<TrashIcon />} label={isDesktop ? label : undefined} variant="secondary" {...rest} />
}
