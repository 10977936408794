import '@s-group/design-system-tokens/web/assets/fonts/SBonusDisplay.css'
import '@s-group/design-system-tokens/web/assets/fonts/SBonusUX.css'

import { Text, TextProps as SDSTextProps } from '@s-group/design-system-components/Text/Text'
import { SDS_TEXT_HEADING_SMALL_REGULAR } from '@s-group/design-system-tokens/web/tokens/text.es6'
import { Colors } from '@ui-components/global/colors'
import { styled } from 'styled-components'

export interface TextProps extends SDSTextProps {
  children: React.ReactNode
  className?: string
  htmlFor?: string
  ['data-testid']?: string
}

// In our design cookbook, LeadText has heading styles, and this is why we need to apply heading styles to a Text element
export const LeadText = styled(Text).attrs({ tagName: 'div' })<TextProps>`
  ${SDS_TEXT_HEADING_SMALL_REGULAR}
`

const BaseText = ({
  className,
  id,
  children,
  tagName = 'span',
  htmlFor,
  ['data-testid']: dataTestId,
  weight,
}: TextProps) => (
  <Text
    id={id}
    className={className}
    weight={weight}
    sizing="medium"
    variant="body"
    tagName={tagName}
    htmlFor={htmlFor}
    data-testid={dataTestId}
  >
    {children}
  </Text>
)

export const BodyText = (props: TextProps) => <BaseText weight="regular" {...props} />

export const LabelText = (props: TextProps) => <BaseText weight="medium" {...props} />

const MutedText = styled(Text)<TextProps>`
  color: ${Colors.TextStrongNeutral};
`

export const AdditionalText = ({ id, className, children, tagName, ['aria-live']: ariaLive }: TextProps) => (
  <MutedText
    id={id}
    className={className}
    weight="regular"
    sizing="small"
    variant="body"
    tagName={tagName}
    aria-live={ariaLive}
  >
    {children}
  </MutedText>
)
