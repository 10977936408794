import { LooseFormSectionContent } from '@ui-components/containers/LooseSectionContent'
import { FormSection } from '@ui-components/global/FormStyling'
import { PropsWithChildren } from 'react'

export const FormSectionContainer = (props: PropsWithChildren<{ ['data-testid']?: string }>) => {
  return (
    <FormSection {...props}>
      <LooseFormSectionContent>{props.children}</LooseFormSectionContent>
    </FormSection>
  )
}
