import { css } from 'styled-components'

export const BoxSizing = css`
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  box-sizing: border-box;
`

export interface AriaAttributes {
  'aria-required': 'true' | 'false'
  'aria-labelledby': string
  'aria-errormessage'?: string
  'aria-describedby'?: string
  'aria-invalid': 'true' | 'false'
}

export function buildAriaAttributes(
  id: string,
  required: boolean,
  hasDescription: boolean,
  hasError: boolean,
  extraDescription?: string,
) {
  const ariaAttributes: AriaAttributes = {
    'aria-required': required ? 'true' : 'false',
    'aria-labelledby': id + '-label',
    'aria-invalid': hasError ? 'true' : 'false',
  }
  const describedBy = []

  if (hasError) {
    // Mark the field as invalid
    ariaAttributes['aria-invalid'] = 'true'

    // keep aria-errormessage for testing
    ariaAttributes['aria-errormessage'] = `${id}-error`

    // Reference the error message
    describedBy.push(`${id}-error`)
  }

  if (hasDescription) {
    describedBy.push(`${id}-description`)
  }

  if (extraDescription) {
    describedBy.push(extraDescription)
  }

  if (describedBy.length) {
    ariaAttributes['aria-describedby'] = describedBy.join(' ')
  }

  return ariaAttributes
}
