import { lazy, Suspense } from 'react'
import { styled } from 'styled-components'

import { IconSize } from './Icons'

const HeartEmoji = lazy(() => import('./emojis/HeartEmoji'))
const HeyThereEmoji = lazy(() => import('./emojis/HeyThereEmoji'))
const IndexFingerUpEmoji = lazy(() => import('./emojis/IndexFingerUpEmoji'))
const RaisedHandsEmoji = lazy(() => import('./emojis/RaisedHandsEmoji'))
const ThumbUpEmoji = lazy(() => import('./emojis/ThumbUpEmoji'))

export type EmojiType = 'thumbUp' | 'raisedHands' | 'heart' | 'heyThere' | 'indexFingerUp'

export const emojis: Record<EmojiType, JSX.Element> = {
  heart: <HeartEmoji />,
  heyThere: <HeyThereEmoji />,
  indexFingerUp: <IndexFingerUpEmoji />,
  raisedHands: <RaisedHandsEmoji />,
  thumbUp: <ThumbUpEmoji />,
}

const EmojiFallback = styled.div`
  width: ${IconSize.EXTRALARGE}px;
  height: ${IconSize.EXTRALARGE}px;
`

export const GetEmoji = (type: EmojiType): JSX.Element => {
  const emoji = emojis[type]
  return <Suspense fallback={<EmojiFallback />}>{emoji}</Suspense>
}
