import * as R from 'remeda'

import { Address } from './types'

export const delayedPromise = <T>(timeout: number, value: T, rejectPromise = false): Promise<T> => {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      if (rejectPromise) {
        reject(new Error('Error', { cause: value }))
      } else {
        resolve(value)
      }
      clearTimeout(timeoutId)
    }, timeout)
  })
}

export const removeLeadingZeros = (str: string): string => str.replace(/^0+/g, '')

export const removeWhitespace = (str: string): string => str.replace(/\s/g, '')

export const stringToBoolean = (value: string | undefined): boolean => {
  return value?.trim()?.toLowerCase() === 'true'
}

export const assertValue = <T>(value: T | null | undefined, param?: string): NonNullable<T> => {
  if (value === undefined || value === null) {
    throw new Error(`Unexpected null or undefined value${param ? " for '" + param + "'" : ''}`)
  }
  return value
}

export const removeUndefinedValues = <T>(object: {
  [key: string]: T | undefined
}): Required<{
  [key: string]: T
}> => {
  const entries = Object.entries(object).filter(([key, value]) => key !== undefined && value !== undefined)
  const o = R.fromPairs(entries)
  return o as Required<{
    [key: string]: T
  }>
}

type FalsyType = false | null | undefined | '' | 0

export const typedBoolean = <ValueType>(value: ValueType): value is Exclude<ValueType, FalsyType> => {
  return Boolean(value)
}

export const isValidAddress = (address: Address): boolean => {
  const { domesticAddress, foreignAddress } = address
  if (domesticAddress) {
    return !!(domesticAddress.street && domesticAddress.postalCode && domesticAddress.postOffice)
  }
  return !!(foreignAddress?.street && foreignAddress?.countryName)
}

export const isNationalityUsa = (nationalities: string[]): boolean => {
  return nationalities.some((nationality) => nationality.toUpperCase() === 'US')
}

export const roundNumberToFractionDigits = (number: number, fractionDigits = 2): number => {
  return parseFloat(number.toFixed(fractionDigits))
}
