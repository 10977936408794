import { Heading, HeadingProps as SDSHeadingProps } from '@s-group/design-system-components/Heading/Heading'
import { Colors } from '@ui-components/global/colors'
import { styled } from 'styled-components'

interface HeadingProps extends SDSHeadingProps {
  'data-testid'?: string
}

/**
 * Do not use these primitives by themselves
 * Use PrimaryHeading, SecondaryHeading and QuaternaryHeading components instead
 */

const BaseHeading = styled(Heading)<{ $color?: string }>`
  margin: 0;
  color: ${({ $color }) => $color};
`

export const MainHeading = ({ className, children, tagName = 'h1', id, color }: HeadingProps) => {
  return (
    <BaseHeading
      className={className}
      id={id}
      variant="display"
      weight="bold"
      transform="uppercase"
      sizing="small"
      tagName={tagName}
      $color={color}
    >
      {children}
    </BaseHeading>
  )
}

export const LargeHeading = ({ children, tagName = 'h2', id, 'data-testid': dataTestId }: HeadingProps) => (
  <BaseHeading
    id={id}
    variant="display"
    weight="semibold"
    sizing="xsmall"
    tagName={tagName}
    $color={Colors.TextMediumPrimary}
    data-testid={dataTestId}
  >
    {children}
  </BaseHeading>
)

export const SmallHeading = ({ children, weight = 'bold', tagName, id, 'data-testid': dataTestId }: HeadingProps) => (
  <BaseHeading id={id} weight={weight} sizing="small" tagName={tagName} data-testid={dataTestId}>
    {children}
  </BaseHeading>
)

export const ExtraSmallHeading = ({
  children,
  weight = 'bold',
  tagName,
  id,
  'data-testid': dataTestId,
}: HeadingProps) => (
  <BaseHeading id={id} weight={weight} sizing="xsmall" tagName={tagName} data-testid={dataTestId}>
    {children}
  </BaseHeading>
)
