import { Colors } from '@ui-components/global/colors'
import {
  BREAKPOINT_DESKTOP_PX,
  BREAKPOINT_LARGE_DESKTOP_PX,
  MAIN_CONTAINER_WIDTH_LARGE_DESKTOP_PX,
} from '@ui-components/global/constants'
import { StandardShadow } from '@ui-components/global/shadow'
import { styled } from 'styled-components'

export const PaperContainer = styled.div<{
  $hideBoxShadow?: boolean
  $largeScreenSupport?: boolean
  $fullWidthSupport?: boolean
}>`
  max-width: ${BREAKPOINT_DESKTOP_PX - 1}px;
  background-color: ${Colors.White};
  ${(props) => (props.$hideBoxShadow ? undefined : StandardShadow)}
  border: 0;
  width: 100%;

  ${(props) =>
    props.$largeScreenSupport &&
    `
      @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
        max-width: ${MAIN_CONTAINER_WIDTH_LARGE_DESKTOP_PX}px;
      }
    `}

  ${(props) =>
    props.$fullWidthSupport &&
    `
      width: 100vw;
      max-width: 2000px;
      margin: auto;
    `}
`
