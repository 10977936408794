import { VisuallyHidden } from '@s-group/design-system-components/VisuallyHidden/VisuallyHidden'
import { ProgressBarStepProps } from '@ui-components/global/ComponentProps'
import { useEffect, useState } from 'react'
import { styled } from 'styled-components'

import { Colors } from '../global/colors'

interface SegmentBarProps {
  $current: number
  $index: number
}

const SegmentBarWrapper = styled.output`
  background-color: ${Colors.White};
  display: flex;
  justify-content: space-between;
`

const SegmentBar = styled.div<SegmentBarProps>`
  width: 100%;
  height: 2px;
  margin-right: 3px;
  background-color: ${(props) =>
    props.$current - props.$index > 0 ? Colors.ElementDefaultNeutral : Colors.TextWeakNeutral};

  &:last-of-type {
    margin-right: 0;
  }
`

export const SegmentedProgressBar = (props: ProgressBarStepProps) => {
  const [announce, setAnnounce] = useState(false)
  const { totalSteps, currentStepIndex, 'aria-label': ariaLabel } = props
  const builtSegments = [...Array(totalSteps).keys()].map((segment, index) => (
    <SegmentBar className={`${segment}`} $current={currentStepIndex} $index={index} key={segment} />
  ))

  useEffect(() => {
    // Render status label after component is rendered and with a small delay
    // so that thr automatically focused heading is read first (tested with NVDA)
    const announceTimeout = setTimeout(() => {
      setAnnounce(true)
    }, 1000)

    return () => clearTimeout(announceTimeout)
  }, [])

  return (
    <SegmentBarWrapper data-testid="progress-bar">
      {announce && <VisuallyHidden>{ariaLabel}</VisuallyHidden>}
      {builtSegments}
    </SegmentBarWrapper>
  )
}
