import { GenericErrorIcon, IconSize, SadIcon } from '@ui-components/assets/Icons'
import { TertiaryHeading } from '@ui-components/typography/headings/TertiaryHeading'
import { BodyText } from '@ui-components/typography/Typography'
import * as React from 'react'
import { styled } from 'styled-components'

import { Colors } from '../global/colors'
import { SPACE_SMALL, SPACE_XXXSMALL } from '../global/spacing'
import { ErrorVisualMode } from './ErrorComponentTypes'

export interface ErrorProps {
  heading: React.ReactNode | string
  content: React.ReactNode
  mode: ErrorVisualMode
}

const StyledTertiaryHeading = styled(TertiaryHeading)`
  color: ${Colors.TextDefaultNeutral};
  margin-top: ${SPACE_SMALL};
  margin-bottom: ${SPACE_XXXSMALL};
`

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
`

const IconWrapper = styled.div`
  color: ${Colors.ElementMediumNeutral};
`

const Content = styled(BodyText)`
  p {
    margin: 0 !important;
  }
`

const ERROR_ICONS: Record<ErrorVisualMode, JSX.Element | undefined> = {
  [ErrorVisualMode.Plain]: undefined,
  [ErrorVisualMode.Sad]: <SadIcon style={{ color: Colors.ElementMediumNeutral }} size={IconSize.LARGE} />,
  [ErrorVisualMode.TechnicalError]: <GenericErrorIcon />,
}

export const ErrorComponent = ({ heading, content, mode }: ErrorProps) => {
  const errorIcon = ERROR_ICONS[mode]
  const headingElement = typeof heading === 'string' ? <StyledTertiaryHeading header={heading} tagName="h2" /> : heading

  return (
    <ErrorWrapper>
      {errorIcon ? <IconWrapper>{errorIcon}</IconWrapper> : null}
      {headingElement}
      <Content>{content}</Content>
    </ErrorWrapper>
  )
}
