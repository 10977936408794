export const MAX_SHORT_TEXT_DEFAULT_LENGTH = 50
export const MAX_FIRST_OR_LASTNAME_DEFAULT = 50
export const MAX_DOMESTIC_STREET_ADDRESS_TEXT_FIELD_LENGTH = 35
export const MAX_FOREIGN_STREET_ADDRESS_TEXT_FIELD_LENGTH = 35
export const MAX_FOREIGN_ADDRESS_LINE_1_TEXT_FIELD_LENGTH = 10
export const MAX_FOREIGN_ADDRESS_LINE_2_TEXT_FIELD_LENGTH = 35
export const MAX_GIIN_ID_TEXT_FIELD_LENGTH = 19
export const MAX_SPONSOR_NAME_TEXT_FIELD_LENGTH = 100

export const EMAIL_ADDRESS_MIN_LENGTH = 6
export const EMAIL_ADDRESS_MAX_LENGTH = 70

export const allowedCharsSeq =
  '\\f\\x8ca-zA-Z0-9\\r\\n!#\\$%&\\(\\)\\*\\+,\\-\\./:;=\\?@\\^~\\|\\(\\)\\[\\]\\{\\}\\xa0\\xa1\\xa2\\xa3\\xa4\\xa5\\xa6\\xa7\\xa8\\xa9\\xaa\\xab\\xac\\xad\\xae\\xaf\\xb0\\xb1\\xb2\\xb3\\xb4\\xb5\\xb6\\xb7\\xb8\\xb9\\xba\\xbb\\xbc\\xbd\\xbe\\xbf\\xc0\\xc1\\xc2\\xc3\\xc4\\xc5\\xc6\\xc7\\xc8\\xc9\\xca\\xcb\\xcc\\xcd\\xce\\xcf\\xd0\\xd1\\xd2\\xd3\\xd4\\xd5\\xd6\\xd7\\xd8\\xd9\\xda\\xdb\\xdc\\xdd\\xde\\xdf\\xe0\\xe1\\xe2\\xe3\\xe4\\xe5\\xe6\\xe7\\xe8\\xe9\\xea\\xeb\\xec\\xed\\xee\\xef\\xf0\\xf1\\xf2\\xf3\\xf4\\xf5\\xf6\\xf7\\xf8\\xf9\\xfa\\xfb\\xfc\\xfd\\xfe\\xff'

export const allowedCharsRule = `^[${allowedCharsSeq}]+$`
export const allowedCharsWithSpaceSeq = `[\\x20${allowedCharsSeq}]*[${allowedCharsSeq}][\\x20${allowedCharsSeq}]*`

export const noDoubleSpaceDashDotSeq = '(?!.*[\\s.-]{2})'
export const noEndWithSpaceDashDotSeq = '(?!.*[\\s.-]$)'

export const allowedCharsWithSpaceRule = `^${allowedCharsWithSpaceSeq}$`
export const allowedCharsWithUnderscoreRule = `^[${allowedCharsSeq}_]+$`
export const allowedCharsWithUnderscoreAndSpaceRule = `^[\\x20${allowedCharsSeq}_]*[${allowedCharsSeq}_][\\x20${allowedCharsSeq}_]*$`
export const ibanValidationRule = `^[A-Z]{2}\\d{2}(\\s?\\d{4}){3}(\\s?\\d{2})$`
export const browserLocaleRule = '^[a-z]{2}-[A-Z]{2}$'

export const finnishDateRule = '^(\\d{1,2})\\.(\\d{1,2})\\.(\\d{4})$'
export const uuidV4ValidationRule = `^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$`
export const postalCodeRule = `^\\d{5}$`
export const foreignPostalCodeRule = `^${noDoubleSpaceDashDotSeq}(?![\\s.-])${noEndWithSpaceDashDotSeq}[a-zA-Z0-9 .-]{1,10}$`
export const verificationCodeRule = `^\\d{4}$`
export const emailAddressRule = `^([a-zA-Z0-9_%+-]+\\.)*[a-zA-Z0-9_%+-]+@([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,63}$`
export const realEstateIdRule = `^\\d{1,3}-\\d{1,3}-\\d{1,4}-\\d{1,4}$`

/* NOTE: Safari does not support lookbehind regexps (https://caniuse.com/js-regexp-lookbehind). Thus we have separate
   rules for frontend and backend validation. They do the same thing except for handling leading and trailing
   whitespaces which in frontend is implemented separately to validation */
export const nameValidationRuleBackend = `(?=(?:^[a-zA-ZäÄöÖüÜåÅ\\-._]))([ a-zA-ZäÄöÖüÜåÅ\\-._]+)(?<=[^\\s])$`
export const getNameValidationRuleFrontend = (extraChars: string = ''): string =>
  `^[ a-zA-ZäÄöÖüÜåÅ${extraChars}\\-._]+$`

export const finnishMobilePhoneNumberRule = `^\\+358(4[01234569]|50)\\d{4,9}$`
export const phoneCountryCodeRule = `^\\+\\d{1,3}`
export const phoneNumberRule = `^[0-9]{4,18}$`
export const phoneNumberWithCountryCodeRule = `${phoneCountryCodeRule}${phoneNumberRule.substring(1)}`

export const monthAndYearRule = `^(0[1-9]|10|11|12)\\s\\/\\s(19\\d{2}|20\\d{2})$`

export const ssnRule = `^[0-9]{6}[A-FU-Y\\-\\+][0-9]{3}[0-9|A-Y]$`

export const countryCodeRule = `^[A-Z]{2}$`

export const municipalityCodeRule = `^\\d{3}$`

export const base64UrlRule = `^[A-Za-z0-9_-]+$`

export const yearMonthAndDayRule = `^[0-9]{4}-[0-9]{2}-[0-9]{2}$`

/**
 * @param birthdate Birthdate in format 'yyyy-MM-dd'
 * @returns True if a person with given birthdate is underaged, otherwise false
 */
export const isUnderaged = (birthdate: string): boolean => {
  if (!birthdate || !/^\d{4}-\d{2}-\d{2}$/.test(birthdate)) {
    return false
  }
  const currentTime = Date.now()
  const now = new Date(currentTime)
  const minLimit = now.setFullYear(now.getFullYear() - 18)
  const date = new Date(birthdate).getTime()
  return date > minLimit && date < currentTime
}

/**
 * @param ssn Finnish SSN starting with 'ddMMyy'
 * @returns True if a person with given SSN is underaged, otherwise false
 */
export const isUnderagedSsn = (ssn: string): boolean => {
  if (!ssn || !/^\d{6}.{5}$/.test(ssn) || !/^[A-F]$/i.test(ssn.charAt(6))) {
    return false
  }
  // convert ddMMyy -> 20yy-mm-dd
  const birthdate = `20${ssn.substring(4, 6)}-${ssn.substring(2, 4)}-${ssn.substring(0, 2)}`
  return isUnderaged(birthdate)
}

export const MAX_REASONABLE_NUMBER = 999_999_999
export const MIN_REASONABLE_NUMBER = 1

export const monetaryValueRule = `^\\d{1,12}(?:,\\d{0,2})?$`

export const percentageValueRule = `^(100(\\.\\d{1,2})?|\\d{1,2}(\\,\\d{1,2})?)$`

export const businessIdRule = `^\\d{7}-\\d$`

export const companyRegisterNumberRule = `^\\d{1,10}$`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const COMMON_INVALID_STRING: any = 'foo'
