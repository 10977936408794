import { FlexColumnContainer } from '@ui-components/global/CommonStyling'
import { PAGE_COMPONENT_GAP } from '@ui-components/global/constants'
import { styled } from 'styled-components'

/**
 * Default container to layout form elements, with 3.5rem (56px) gap between elements. Consider using FormSectionContainer if section is needed.
 */
export const LooseFormSectionContent = styled(FlexColumnContainer)`
  padding: 0;
  gap: ${PAGE_COMPONENT_GAP}px;
  margin-bottom: 0px;

  // Headers related? see next comment below
  > :first-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  > span {
    display: block;
  }

  // These should be global style
  h1,
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
`
