import { PaperContainer } from '@ui-components/containers/PaperContainer'
import { FullWidthPageContentSection, Section } from '@ui-components/global/LayoutStyling'
import { PropsWithChildren, ReactNode } from 'react'
import { styled } from 'styled-components'

import { BREAKPOINT_DESKTOP_PX } from '../global/constants'

const ContentWrapper = styled.div<{ fullWidthFooter?: boolean }>`
  max-width: ${(props) => (!props.fullWidthFooter ? `${BREAKPOINT_DESKTOP_PX - 1}px;` : 'inherit')};
`

const footerAttributes = {
  role: 'region',
}

const FooterSection = styled(FullWidthPageContentSection).attrs(footerAttributes)``

const BottomPadding = styled.div`
  padding-bottom: 64px;
`

interface WideContainerProps extends PropsWithChildren {
  footerContent?: ReactNode
  fullWidthFooter?: boolean
  ['data-testid']?: string
}

export const WideContainer = (props: WideContainerProps) => {
  const { footerContent, children, fullWidthFooter } = props
  return (
    <BottomPadding>
      <PaperContainer data-testid={props['data-testid'] ?? 'entrance'} $fullWidthSupport={true}>
        {children}
      </PaperContainer>
      {footerContent && (
        <FooterSection $backgroundColor={'transparent'}>
          <ContentWrapper fullWidthFooter={fullWidthFooter}>
            <Section halfHorizontalPadding={fullWidthFooter}>{footerContent}</Section>
          </ContentWrapper>
        </FooterSection>
      )}
    </BottomPadding>
  )
}
