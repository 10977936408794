import { ExtraTightSectionContent } from '@ui-components/containers/ExtraTightSectionContent'

import { AdditionalText } from '../Typography'
import { ExtraSmallHeading } from './headingPrimitives'

export interface QuaternaryHeadingProps {
  header: string
  id?: string
  tagName?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  additionalText?: string
  className?: string
  tabIndex?: number
  weight?: 'bold' | 'medium'
  additionalTextId?: string
}

export const QuaternaryHeading = ({
  header,
  id,
  tagName = 'h4',
  additionalText,
  className,
  tabIndex,
  weight,
  additionalTextId,
}: QuaternaryHeadingProps) => {
  return (
    <ExtraTightSectionContent className={className} tabIndex={tabIndex}>
      <ExtraSmallHeading tagName={tagName} id={id} weight={weight}>
        {header}
      </ExtraSmallHeading>
      {additionalText && <AdditionalText id={additionalTextId}>{additionalText}</AdditionalText>}
    </ExtraTightSectionContent>
  )
}
