import { FlexColumnContainer } from '@ui-components/global/CommonStyling'
import { SPACE_SMALL } from '@ui-components/global/spacing'
import { styled } from 'styled-components'

/**
 * Container with tight spacing, with 1rem (16px) gap between elements
 */
export const TightSectionContent = styled(FlexColumnContainer)`
  padding: 0;
  gap: ${SPACE_SMALL};
  margin-bottom: 0px;
`
