import { Button as DSButton, ButtonProps as DSButtonProps } from '@s-group/design-system-components/Button/Button'
import { VisuallyHidden } from '@s-group/design-system-components/VisuallyHidden/VisuallyHidden'
import { LoadingSpinner } from '@ui-components/decorative/LoadingSpinner'
import { MouseEventHandler, RefAttributes } from 'react'
import { styled } from 'styled-components'

import { Colors } from '../global/colors'

export type ButtonVariant = 'primary' | 'secondary' | 'plain'

export interface ButtonProps {
  'aria-disabled'?: boolean
  'aria-label'?: string
  color?: 'primary' | 'neutral'
  compact?: boolean
  'data-testid'?: string
  'data-testlabel'?: string
  disabled?: boolean
  icon?: JSX.Element
  iconPos?: 'before' | 'after'
  label?: string
  loading?: boolean
  loadingMode?: 'icon' | 'label'
  mode?: 'default' | 'danger'
  onClick?: MouseEventHandler<HTMLButtonElement>
  sizing?: 'xsmall' | 'small'
  type?: 'button' | 'submit'
  variant?: ButtonVariant
  tabIndex?: number
}

const TypedDSButton = DSButton as React.ComponentType<DSButtonProps & RefAttributes<HTMLButtonElement>>

export const Button = ({
  'aria-disabled': ariaDisabled,
  'aria-label': ariaLabel,
  color,
  disabled,
  icon,
  label,
  loading = false,
  loadingMode = 'label',
  mode = 'default',
  sizing = 'small',
  type = 'button',
  variant = 'primary',
  ...rest
}: ButtonProps) => {
  const variantProps: Record<ButtonVariant, Partial<DSButtonProps>> = {
    primary: { variant: 'filled' },
    secondary: { variant: 'outlined', color: 'neutral' },
    plain: { variant: 'plain', style: { fontWeight: 400 } },
  }

  const Loader = <LoadingSpinner aria-hidden={true} size="small" alt="" />

  return (
    <TypedDSButton
      aria-disabled={ariaDisabled || disabled || loading}
      aria-label={ariaLabel}
      color={color}
      disabled={disabled}
      icon={loading && loadingMode === 'icon' ? Loader : icon}
      rounding="pill"
      sizing={sizing}
      style={mode === 'danger' ? { color: Colors.TextError } : undefined}
      type={type}
      {...variantProps[variant]}
      {...rest}
    >
      {loading && loadingMode === 'label' ? (
        <LoadingContainer aria-live="polite">
          {Loader}
          <VisuallyHidden>{ariaLabel ?? label}</VisuallyHidden>
        </LoadingContainer>
      ) : (
        <span>{label}</span>
      )}
    </TypedDSButton>
  )
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
