import { MakeRequired } from '@shared'
import { PenIcon } from '@ui-components/assets/Icons'
import { useIsDesktopScreen } from '@ui-components/hooks/useIsDesktopScreen'

import { Button, ButtonProps } from '../Button'

type SelectedButtonProps = Pick<
  ButtonProps,
  'aria-label' | 'compact' | 'data-testid' | 'data-testlabel' | 'label' | 'onClick' | 'variant'
>
export type EditItemButtonProps = MakeRequired<SelectedButtonProps, 'aria-label' | 'label'>

export const EditItemButton = ({ label, ...rest }: EditItemButtonProps) => {
  const isDesktop = useIsDesktopScreen()
  return <Button icon={<PenIcon />} label={isDesktop ? label : undefined} variant="secondary" {...rest} />
}
