import { AdditionalText, BodyText, LabelText } from '@ui-components/typography/Typography'
import { ReactNode } from 'react'

export const Label = ({
  id,
  label,
  description,
  className,
  children,
  isRequired = false,
  labelIdOverride = undefined,
  as = 'label',
  useBodyText = false,
}: {
  id: string
  label: string
  description?: ReactNode
  className?: string
  children?: ReactNode
  isRequired?: boolean
  labelIdOverride?: string
  as?: 'label' | 'legend' | 'span'
  useBodyText?: boolean
}): JSX.Element => {
  const formattedLabel = isRequired ? `* ${label}` : label
  const TextComponent = useBodyText ? BodyText : LabelText
  return (
    <div className={className}>
      <TextComponent tagName={as} id={labelIdOverride ?? `${id}-label`} htmlFor={id}>
        {formattedLabel}
      </TextComponent>

      {description && (
        <AdditionalText tagName="div" id={`${id}-description`}>
          {description}
        </AdditionalText>
      )}

      {children && <BodyText tagName="div">{children}</BodyText>}
    </div>
  )
}
Label.displayName = 'ElementLabel'
