import { Tab as DSTab } from '@s-group/design-system-components/Tabs/Tab'
import { Tabs as DSTabs } from '@s-group/design-system-components/Tabs/Tabs'
import { LEGACY_SPACE_XLARGE } from '@ui-components/global/legacy/spacing'
import { RefObject } from 'react'
import { styled } from 'styled-components'

const TabContent = styled.div`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  padding-top: ${LEGACY_SPACE_XLARGE};
  flex-direction: column;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-sizing: border-box;
`

export interface TabProperties {
  id: string
  component: JSX.Element
  title: string
  selected?: boolean
}

interface TabsProperties {
  tabs: TabProperties[]
  alignment?: 'full' | 'left'
  onTabChange: (selectedTabRef: RefObject<HTMLDivElement>, index: number) => void
}

export const Tabs = ({ tabs, alignment = 'full', onTabChange }: TabsProperties) => {
  return (
    <DSTabs alignment={alignment} color="neutral" onTabSelect={onTabChange}>
      {tabs.map((tab) => (
        <DSTab key={tab.id} label={tab.title} selected={tab.selected}>
          <TabContent key={tab.id}>{tab.component}</TabContent>
        </DSTab>
      ))}
    </DSTabs>
  )
}
