import { AdditionalText } from '@ui-components/typography/Typography'
import { useId } from 'react'
import { styled } from 'styled-components'

import { PlusIcon } from '../../assets/Icons'
import { Button } from '../Button'

export interface AddItemButtonProps {
  onClick: (event: React.BaseSyntheticEvent) => unknown
  label: string
  description?: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const AddItemButton = (props: AddItemButtonProps) => {
  const descriptionId = useId()

  return (
    <Container>
      {props.description && <AdditionalText id={descriptionId}>{props.description}</AdditionalText>}
      <Button
        onClick={props.onClick}
        label={props.label}
        aria-describedby={descriptionId}
        icon={<PlusIcon />}
        compact={true}
        variant="plain"
      />
    </Container>
  )
}
