import { VisuallyHidden } from '@s-group/design-system-components/VisuallyHidden/VisuallyHidden'
import { Button } from '@ui-components/actions/Button'
import { BodyText, LabelText } from '@ui-components/typography/Typography'
import { styled } from 'styled-components'

import { HideIcon, ShowIcon } from '../assets/Icons'
import { FlexRowContainer } from '../global/CommonStyling'

export interface SensitiveDataProps {
  label: string
  hiddenActionLabel: string
  hiddenActionAriaLabel: string
  hiddenValueAriaLabel: string
  shownActionLabel: string
  shownActionAriaLabel: string
  shownValueAriaLabelGenerator: (value: string) => string
  value: string | undefined
  isLoading: boolean
  loadingAriaLabel: string
  onClick: () => void
}

const Container = styled(FlexRowContainer)`
  justify-content: space-between;
  align-items: center;
`

export const SensitiveData = ({
  label,
  hiddenActionLabel,
  hiddenActionAriaLabel,
  isLoading,
  loadingAriaLabel,

  shownActionLabel,
  shownActionAriaLabel,
  hiddenValueAriaLabel,
  shownValueAriaLabelGenerator,
  value,
  onClick,
}: SensitiveDataProps): JSX.Element => {
  const shouldShow = value !== undefined
  const valueToShow = shouldShow ? value : '●●●●●●●●'

  const icon = shouldShow ? <HideIcon /> : <ShowIcon />
  const ariaActionLabel = shouldShow ? shownActionAriaLabel : hiddenActionAriaLabel
  const labelsToUse = {
    actionLabel: shouldShow ? shownActionLabel : hiddenActionLabel,
    ariaActionLabel: isLoading ? loadingAriaLabel : ariaActionLabel,
  }

  const doClick = () => {
    if (!isLoading) {
      onClick()
    }
  }

  return (
    <Container>
      <div>
        <LabelText tagName="div">{label}</LabelText>
        <BodyText>
          <span aria-hidden={true}>{valueToShow}</span>
          <div aria-live="polite">
            {shouldShow ? (
              <VisuallyHidden>{shownValueAriaLabelGenerator(valueToShow)}</VisuallyHidden>
            ) : (
              <VisuallyHidden>{hiddenValueAriaLabel}</VisuallyHidden>
            )}
          </div>
        </BodyText>
      </div>
      <div>
        <Button
          icon={icon}
          loading={isLoading}
          loadingMode="icon"
          label={labelsToUse.actionLabel}
          onClick={doClick}
          aria-label={labelsToUse.ariaActionLabel}
          variant="plain"
        />
      </div>
    </Container>
  )
}
