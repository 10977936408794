import { Link as DSLink, LinkProps as DSLinkProps } from '@s-group/design-system-components/Link/Link'
import { VisuallyHidden } from '@s-group/design-system-components/VisuallyHidden/VisuallyHidden'
import { ReactNode, RefAttributes } from 'react'
import { styled } from 'styled-components'

const ExternalLinkWrapper = styled.span``

export interface ExternalLinkProps extends DSLinkProps {
  children: ReactNode
  target?: '_blank' | '_top'
}

const TypedDSLink = DSLink as React.ComponentType<DSLinkProps & RefAttributes<HTMLLinkElement>>

export const ExternalLink = ({ children, onClick, href, target, 'aria-label': ariaLabel }: ExternalLinkProps) => {
  return (
    <ExternalLinkWrapper>
      <TypedDSLink onClick={onClick} external={true} href={href} target={target || '_blank'} rel="noreferrer">
        {children}
        {ariaLabel && <VisuallyHidden>{ariaLabel}</VisuallyHidden>}
      </TypedDSLink>
    </ExternalLinkWrapper>
  )
}
