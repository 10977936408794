import { useEffect, useState } from 'react'

export const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState(() => window.matchMedia(query).matches)

  useEffect(() => {
    const mediaMatch = window.matchMedia(query)
    const handler = (e: Event) => setMatches((e as MediaQueryListEvent).matches)
    mediaMatch.addEventListener('change', handler)

    return () => mediaMatch.removeEventListener('change', handler)
  }, [query, setMatches])
  return matches
}
