import { Section } from '@ui-components/global/LayoutStyling'
import { StandardShadow } from '@ui-components/global/shadow'
import { useEffect, useLayoutEffect } from 'react'
import { styled } from 'styled-components'

import { mainContentAttributes } from '../global/CommonStyling'
import {
  MAIN_STEP_HEADER_HEIGHT,
  PAGE_BOTTOM_PADDING,
  PAGE_CONTENT_TOP_MARGIN,
  STEP_HEADER_BOTTOM_MARGIN,
  STEP_HEADER_HEIGHT,
  STEP_HEADER_SMALL_HEIGHT,
} from '../global/constants'
import { useIsScrolledOver } from '../hooks'
import { isFirefox } from '../utilities/helpers'
import { WizardHeader } from './WizardHeader'
import { WizardContainerProps } from './WizardTypes'

const Main = styled.div.attrs(mainContentAttributes)``

const getMarginTop = (hasSubheader: boolean): number =>
  PAGE_CONTENT_TOP_MARGIN +
  MAIN_STEP_HEADER_HEIGHT +
  STEP_HEADER_BOTTOM_MARGIN +
  (hasSubheader ? STEP_HEADER_HEIGHT - STEP_HEADER_SMALL_HEIGHT : 0)

const FullWidthHeroSection = styled(Section)<{
  $isHeaderFixed: boolean
  $hasSubheader: boolean
}>`
  ${({ $isHeaderFixed: isHeaderFixed, $hasSubheader: hasSubheader }) =>
    `margin-top: ${isHeaderFixed ? getMarginTop(hasSubheader) : PAGE_CONTENT_TOP_MARGIN}px;`}
  align-items: center;
  border: 0;
  display: flex;
  justify-content: center;
  padding: 0px;
  ${StandardShadow}
`

const Container = styled.div<{
  $isHeaderFixed: boolean
  $hasSubheader: boolean
  $hasHeaderSectionContent: boolean
  $removeContentTopMargin: boolean
}>`
  ${({
    $isHeaderFixed: isHeaderFixed,
    $hasSubheader: hasSubheader,
    $hasHeaderSectionContent: hasHeaderSectionContent,
    $removeContentTopMargin: removeContentTopMargin,
  }) => {
    if (!removeContentTopMargin) {
      return `margin-top: ${isHeaderFixed && !hasHeaderSectionContent ? getMarginTop(hasSubheader) : PAGE_CONTENT_TOP_MARGIN}px;`
    }
  }}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${PAGE_BOTTOM_PADDING}px;
`

export const WizardStepContainer = (props: WizardContainerProps) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isScrolledOverPrimaryBarHeight = useIsScrolledOver(MAIN_STEP_HEADER_HEIGHT)

  // When pressing 'jatka' and there is an error in the form, Firefox scrolls too little and secondary header hides the error.
  useEffect(() => {
    if (isFirefox()) {
      const handleResize = () => {
        const scrollPaddingTop = `${Math.floor(window.innerHeight / 2)}px`
        document.documentElement.style.scrollPaddingTop = scrollPaddingTop
      }

      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  const {
    subHeaderOptions,
    shouldShowMainHeader,
    title,
    icon,
    headerButtonProps,
    pageHeroContent,
    $useLegacyBrand,
    children,
    additionalText,
    hideBoxShadow,
    legacyStickyTopBar = true,
    removeContentTopMargin = false,
    transparentTopBar,
    topBarVariant,
  } = props

  const hasSubheader = !!subHeaderOptions
  const hasHeaderSectionContent = !!pageHeroContent
  const fixedSecondaryTopBar = legacyStickyTopBar && isScrolledOverPrimaryBarHeight

  return (
    <>
      <WizardHeader
        shouldShowMainHeader={shouldShowMainHeader}
        title={title}
        icon={icon}
        headerButtonProps={headerButtonProps}
        subHeaderOptions={subHeaderOptions}
        $useLegacyBrand={$useLegacyBrand}
        fixToTop={fixedSecondaryTopBar}
        additionalText={additionalText}
        hideBoxShadow={hideBoxShadow}
        transparentTopBar={transparentTopBar}
        topBarVariant={topBarVariant}
      />
      <Main>
        {pageHeroContent && (
          <FullWidthHeroSection $isHeaderFixed={isScrolledOverPrimaryBarHeight} $hasSubheader={hasSubheader}>
            {pageHeroContent}
          </FullWidthHeroSection>
        )}
        <Container
          $isHeaderFixed={fixedSecondaryTopBar}
          $hasSubheader={hasSubheader}
          $hasHeaderSectionContent={hasHeaderSectionContent}
          $removeContentTopMargin={removeContentTopMargin}
        >
          {children}
        </Container>
      </Main>
    </>
  )
}
