import {
  LinkButton as DSLinkButton,
  LinkButtonProps as DSLinkButtonProps,
} from '@s-group/design-system-components/LinkButton/LinkButton'
import { MouseEventHandler, RefAttributes } from 'react'

export type LinkButtonVariant = 'primary' | 'plain'

export interface LinkButtonProps {
  'aria-label'?: string
  className?: string
  color?: 'primary' | 'neutral'
  compact?: boolean
  'data-testid'?: string
  href?: string
  icon?: JSX.Element
  iconPos?: 'before' | 'after'
  label?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  target?: string
  variant?: LinkButtonVariant
}

const TypedDSLinkButton = DSLinkButton as React.ComponentType<DSLinkButtonProps & RefAttributes<HTMLLinkElement>>

export const LinkButton = ({
  // Always include href to make link focusable
  href = '',
  label,
  variant = 'primary',
  ...rest
}: LinkButtonProps) => {
  const variantProps: Record<LinkButtonVariant, Partial<DSLinkButtonProps>> = {
    primary: { variant: 'filled' },
    plain: { variant: 'plain', style: { fontWeight: 400 } },
  }

  return (
    <TypedDSLinkButton href={href} role="link" rounding="pill" sizing="small" {...variantProps[variant]} {...rest}>
      <span>{label}</span>
    </TypedDSLinkButton>
  )
}
