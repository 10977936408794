import { SDS_COLOR_WHITE } from '@s-group/design-system-tokens/web/tokens/colors.es6'
import {
  SDS_BRAND_BUTTON_COLOR_BACKGROUND_FILLED_PRIMARY,
  SDS_BRAND_BUTTON_COLOR_BACKGROUND_FILLED_PRIMARY_HOVER,
  SDS_BRAND_BUTTON_COLOR_TEXT_FILLED_PRIMARY,
  SDS_BRAND_COLOR_BACKGROUND_DEFAULT_NEUTRAL,
  SDS_BRAND_COLOR_BACKGROUND_INFO,
  SDS_BRAND_COLOR_BACKGROUND_INVERSE_NEUTRAL_ACTIVE,
  SDS_BRAND_COLOR_BACKGROUND_WEAK_NEUTRAL,
  SDS_BRAND_COLOR_BACKGROUND_WEAK_PRIMARY,
  SDS_BRAND_COLOR_BACKGROUND_WEAKER_SECONDARY,
  SDS_BRAND_COLOR_BACKGROUND_WEAKEST_NEUTRAL,
  SDS_BRAND_COLOR_BACKGROUND_WEAKEST_SECONDARY,
  SDS_BRAND_COLOR_BORDER_DEFAULT_NEUTRAL,
  SDS_BRAND_COLOR_BORDER_ERROR,
  SDS_BRAND_COLOR_BORDER_FOCUS,
  SDS_BRAND_COLOR_BORDER_STRONG_NEUTRAL,
  SDS_BRAND_COLOR_BORDER_WEAK_NEUTRAL,
  SDS_BRAND_COLOR_ELEMENT_DEFAULT_NEUTRAL,
  SDS_BRAND_COLOR_ELEMENT_ERROR,
  SDS_BRAND_COLOR_ELEMENT_INFO,
  SDS_BRAND_COLOR_ELEMENT_MEDIUM_NEUTRAL,
  SDS_BRAND_COLOR_ELEMENT_MEDIUM_PRIMARY,
  SDS_BRAND_COLOR_ELEMENT_MEDIUM_SECONDARY,
  SDS_BRAND_COLOR_ELEMENT_STRONG_NEUTRAL,
  SDS_BRAND_COLOR_ELEMENT_STRONG_PRIMARY,
  SDS_BRAND_COLOR_ELEMENT_STRONGEST_PRIMARY,
  SDS_BRAND_COLOR_TEXT_DEFAULT_NEUTRAL,
  SDS_BRAND_COLOR_TEXT_ERROR,
  SDS_BRAND_COLOR_TEXT_INFO,
  SDS_BRAND_COLOR_TEXT_MEDIUM_PRIMARY,
  SDS_BRAND_COLOR_TEXT_STRONG_NEUTRAL,
  SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY,
  SDS_BRAND_COLOR_TEXT_STRONGER_NEUTRAL,
  SDS_BRAND_COLOR_TEXT_STRONGEST_PRIMARY,
  SDS_BRAND_COLOR_TEXT_WEAK_NEUTRAL,
} from '@s-group/design-system-tokens/web/tokens/s-pankki/colors.es6'

export const Colors = {
  // Generic
  White: SDS_COLOR_WHITE,

  // Background
  BackgroundWeakPrimary: SDS_BRAND_COLOR_BACKGROUND_WEAK_PRIMARY,
  BackgroundWeakestSecondary: SDS_BRAND_COLOR_BACKGROUND_WEAKEST_SECONDARY,
  BackgroundWeakerSecondary: SDS_BRAND_COLOR_BACKGROUND_WEAKER_SECONDARY,
  BackgroundInverseNeutralActive: SDS_BRAND_COLOR_BACKGROUND_INVERSE_NEUTRAL_ACTIVE,
  BackgroundDefaultNeutral: SDS_BRAND_COLOR_BACKGROUND_DEFAULT_NEUTRAL,
  BackgroundWeakNeutral: SDS_BRAND_COLOR_BACKGROUND_WEAK_NEUTRAL,
  BackgroundWeakestNeutral: SDS_BRAND_COLOR_BACKGROUND_WEAKEST_NEUTRAL,
  BackgroundInfo: SDS_BRAND_COLOR_BACKGROUND_INFO,

  // Button
  ButtonBackgroundFilledPrimary: SDS_BRAND_BUTTON_COLOR_BACKGROUND_FILLED_PRIMARY,
  ButtonBackgroundFilledPrimaryHover: SDS_BRAND_BUTTON_COLOR_BACKGROUND_FILLED_PRIMARY_HOVER,
  ButtonTextFilledPrimary: SDS_BRAND_BUTTON_COLOR_TEXT_FILLED_PRIMARY,

  // Border
  BorderDefaultNeutral: SDS_BRAND_COLOR_BORDER_DEFAULT_NEUTRAL,
  BorderStrongNeutral: SDS_BRAND_COLOR_BORDER_STRONG_NEUTRAL,
  BorderWeakNeutral: SDS_BRAND_COLOR_BORDER_WEAK_NEUTRAL,
  BorderError: SDS_BRAND_COLOR_BORDER_ERROR,
  BorderFocus: SDS_BRAND_COLOR_BORDER_FOCUS,

  // Element
  ElementStrongestPrimary: SDS_BRAND_COLOR_ELEMENT_STRONGEST_PRIMARY,
  ElementStrongPrimary: SDS_BRAND_COLOR_ELEMENT_STRONG_PRIMARY,
  ElementMediumPrimary: SDS_BRAND_COLOR_ELEMENT_MEDIUM_PRIMARY,
  ElementMediumSecondary: SDS_BRAND_COLOR_ELEMENT_MEDIUM_SECONDARY,
  ElementDefaultNeutral: SDS_BRAND_COLOR_ELEMENT_DEFAULT_NEUTRAL,
  ElementStrongNeutral: SDS_BRAND_COLOR_ELEMENT_STRONG_NEUTRAL,
  ElementMediumNeutral: SDS_BRAND_COLOR_ELEMENT_MEDIUM_NEUTRAL,
  ElementError: SDS_BRAND_COLOR_ELEMENT_ERROR,
  ElementInformation: SDS_BRAND_COLOR_ELEMENT_INFO,

  // Text
  TextStrongestPrimary: SDS_BRAND_COLOR_TEXT_STRONGEST_PRIMARY,
  TextStrongPrimary: SDS_BRAND_COLOR_TEXT_STRONG_PRIMARY,
  TextMediumPrimary: SDS_BRAND_COLOR_TEXT_MEDIUM_PRIMARY,
  TextDefaultNeutral: SDS_BRAND_COLOR_TEXT_DEFAULT_NEUTRAL,
  TextStrongerNeutral: SDS_BRAND_COLOR_TEXT_STRONGER_NEUTRAL,
  TextStrongNeutral: SDS_BRAND_COLOR_TEXT_STRONG_NEUTRAL,
  TextWeakNeutral: SDS_BRAND_COLOR_TEXT_WEAK_NEUTRAL,
  TextInfo: SDS_BRAND_COLOR_TEXT_INFO,
  TextError: SDS_BRAND_COLOR_TEXT_ERROR,

  // Used for dev only
  Dev: 'darkred',
}

export const StorybookColorChoices = Object.keys(Colors)
  .filter((val) => Number.isNaN(+val))
  .map((color: string, index: number) => ({ name: color, value: Object.values(Colors)[index] }))
