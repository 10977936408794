import { ReactNode } from 'react'
import { styled } from 'styled-components'
import { v4 as uuid } from 'uuid'

import { Colors } from '../global/colors'
import { FONT_FAMILY_S_BONUS_UX } from '../global/constants'
import { SPACE_SMALL } from '../global/spacing'

export interface IconizedListProps {
  listItems: ListItem[]
  color?: string
  centerAlignItems?: boolean
}

export interface ListItem {
  icon: JSX.Element
  content: ReactNode
}

const StyledList = styled.ul<{ $color?: string }>`
  font-family: ${FONT_FAMILY_S_BONUS_UX};
  list-style-type: none;
  padding-inline-start: 0;
  color: ${(props) => props.$color};
`

const StyledListItem = styled.li<{ $centerAlignItems: boolean }>`
  display: flex;
  justify-content: left;
  align-items: ${(props) => (props.$centerAlignItems ? 'center' : 'flex-start')};
  gap: ${SPACE_SMALL};
  &:not(:last-of-type) {
    margin-bottom: calc(1.5 * ${SPACE_SMALL});
  }
`

const IconWrapper = styled.div.attrs({
  'aria-hidden': true,
})`
  display: flex;
`

const ContentWrapper = styled.section`
  margin: 0;
`

export const IconizedList = ({
  listItems,
  color = Colors.TextDefaultNeutral,
  centerAlignItems = true,
}: IconizedListProps): JSX.Element => {
  return (
    <div>
      {listItems.length !== 0 && (
        <StyledList $color={color}>
          {listItems.map((item) => (
            <StyledListItem key={uuid()} $centerAlignItems={centerAlignItems}>
              <IconWrapper>{item.icon}</IconWrapper>
              <ContentWrapper>{item.content}</ContentWrapper>
            </StyledListItem>
          ))}
        </StyledList>
      )}
    </div>
  )
}
