import { ProgressBarStepProps } from '@ui-components/global/ComponentProps'
import { LEGACY_SPACE_LARGE, LEGACY_SPACE_MEDIUM } from '@ui-components/global/legacy/spacing'
import { SPACE_SMALL, SPACE_XXXXLARGE } from '@ui-components/global/spacing'
import { useMediaQuery } from '@ui-components/global/useMediaQuery'
import { useEffect, useRef } from 'react'
import { styled } from 'styled-components'

import { SegmentedProgressBar } from '../../decorative/SegmentedProgressBar'
import { SecondaryHeaderWrapper } from '../../global/CommonStyling'
import {
  BREAKPOINT_DESKTOP_PX,
  BREAKPOINT_LARGE_DESKTOP_PX,
  DEFAULT_SCROLLED_OVER_HEIGHT_PX,
  STEP_HEADER_HEIGHT,
} from '../../global/constants'
import { useIsScrolledOver } from '../../hooks'
import { SecondaryTopBar, SecondaryTopBarProps } from '../../navigation/SecondaryTopBar'

export interface FloatingTopBarProps {
  secondaryTopBarProps?: SecondaryTopBarProps
  progressBarProps?: ProgressBarStepProps
}

export const FloatingBarWrapper = styled.div`
  padding: 0 ${SPACE_SMALL};

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    padding: 0 ${LEGACY_SPACE_LARGE};
  }

  @media (min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px) {
    padding: 0 ${SPACE_XXXXLARGE};
  }
`

export const FloatingTopBar = ({ secondaryTopBarProps, progressBarProps }: FloatingTopBarProps) => {
  const showFloatingTopBar = useIsScrolledOver(DEFAULT_SCROLLED_OVER_HEIGHT_PX)
  const isLargeDesktopSize = useMediaQuery(`(min-width: ${BREAKPOINT_LARGE_DESKTOP_PX}px)`)
  const showStaticTopBar = Boolean(
    !progressBarProps || secondaryTopBarProps?.infoButtonProps || secondaryTopBarProps?.previousButtonProps,
  )

  const showProgressBar = progressBarProps && showFloatingTopBar
  const staticTopBarProps: Partial<SecondaryTopBarProps> = {
    ...secondaryTopBarProps,
    // Override default values in secondaryTopBarProps
    title: undefined,
    description: undefined,
    removeSidePadding: true,
    hideBoxShadow: true,
    hideButtonLabelsInMobileBreakpoint: false,
  }

  let topBarProps

  if (secondaryTopBarProps) {
    if (showFloatingTopBar) {
      topBarProps = secondaryTopBarProps
    } else if (showStaticTopBar) {
      topBarProps = staticTopBarProps
    }
  }

  const floatingBarRef = useRef<HTMLDivElement | null>(null)
  const timerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
  useEffect(() => {
    if (timerRef.current !== undefined) {
      clearTimeout(timerRef.current)
    }
    timerRef.current = setTimeout(
      () => {
        floatingBarRef.current?.classList.add('visible')
      },
      process.env.NODE_ENV === 'test' ? 0 : 10,
    )
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [showFloatingTopBar])

  return (
    <Header $showStaticTopBar={showStaticTopBar} $isLargeDesktopSize={isLargeDesktopSize}>
      {topBarProps && (
        <>
          {!showFloatingTopBar && (
            <SecondaryHeader $fixedToTop={false} data-testid="secondary-header">
              <SecondaryTopBar {...topBarProps} small={false} titleTagName="h2" />
            </SecondaryHeader>
          )}
          {showFloatingTopBar && (
            <FloatingSecondaryHeader ref={floatingBarRef} $fixedToTop={true} data-testid="secondary-header">
              <SecondaryTopBar {...secondaryTopBarProps} small={false} titleTagName="h2" />
              {showProgressBar && <SegmentedProgressBar {...progressBarProps} />}
            </FloatingSecondaryHeader>
          )}
        </>
      )}
    </Header>
  )
}

const Header = styled.div<{ $showStaticTopBar: boolean; $isLargeDesktopSize: boolean }>`
  height: ${({ $showStaticTopBar }) => ($showStaticTopBar ? `${STEP_HEADER_HEIGHT}px` : undefined)};
  margin-bottom: ${({ $showStaticTopBar, $isLargeDesktopSize }) =>
    $showStaticTopBar && $isLargeDesktopSize ? `${LEGACY_SPACE_MEDIUM}` : undefined};
`

const SecondaryHeader = styled(SecondaryHeaderWrapper)`
  height: ${STEP_HEADER_HEIGHT}px;
  margin-bottom: 0;
`

const FloatingSecondaryHeader = styled(SecondaryHeader)`
  transition: all 200ms;
  top: -${DEFAULT_SCROLLED_OVER_HEIGHT_PX}px;
  &.visible {
    top: 0;
  }
}
`
