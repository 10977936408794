import { typedBoolean } from '@shared/helpers'
import { PaperContainer } from '@ui-components/containers/PaperContainer'
import { TightSectionContent } from '@ui-components/containers/TightSectionContent'
import { StandardShadow } from '@ui-components/global/shadow'
import { SPACE_SMALL, SPACE_XSMALL } from '@ui-components/global/spacing'
import { SecondaryHeading } from '@ui-components/typography/headings/SecondaryHeading'
import { BodyText } from '@ui-components/typography/Typography'
import * as React from 'react'
import { styled } from 'styled-components'

import { InfoIcon } from '../assets/Icons'
import { Colors } from '../global/colors'
import { LogoIconType } from '../global/ComponentProps'
import { Assistant } from './Assistant'
import { LargeScreenContainer } from './LargeScreenContainer'
import { WizardHeader } from './WizardHeader'
import { WizardHeaderButtonProps, WizardPageSubHeaderProps } from './WizardTypes'

export interface AfterCareStepProps {
  stepId: string
  icon: LogoIconType
  title?: string
  topic: string
  secondaryHeadingHeader?: string
  primaryHeadingLeadText?: string
  text?: React.ReactNode[]
  headerButtonProps: WizardHeaderButtonProps
  notification?: string
  children?: React.ReactNode
  subHeaderOptions?: WizardPageSubHeaderProps
  shouldUsePaperContainer?: boolean
  twoColumnLayoutSupport?: boolean
  fullViewportHeight?: boolean
}

const Wrapper = styled.div`
  height: 100vh;
`

const Main = styled.main<{ $hasSubHeader?: boolean }>`
  ${StandardShadow};
  margin-top: ${({ $hasSubHeader }) => ($hasSubHeader ? '0' : SPACE_XSMALL)};
`

const PaperContainerWrapper = styled.div`
  color: ${Colors.TextDefaultNeutral};
`

const NotificationWrapper = styled.div`
  display: flex;
  gap: ${SPACE_SMALL};
`

const NotificationArea = styled.div`
  color: ${Colors.TextInfo};
`

const StyledTightSectionContent = styled(TightSectionContent)`
  align-items: start;
`

export const AfterCareStep = ({
  title,
  topic,
  icon,
  secondaryHeadingHeader,
  primaryHeadingLeadText,
  text,
  notification,
  children,
  headerButtonProps,
  subHeaderOptions,
  stepId,
  shouldUsePaperContainer = true,
  twoColumnLayoutSupport = false,
  fullViewportHeight = false,
}: AfterCareStepProps) => {
  const textArray = (Array.isArray(text) ? text : [text]).filter(typedBoolean)
  const leadTexts = textArray.map((paragraph, i) => <p key={`text-${i}`}>{paragraph}</p>)

  return (
    <Wrapper>
      <WizardHeader
        shouldShowMainHeader={true}
        title={title}
        icon={icon}
        headerButtonProps={headerButtonProps}
        subHeaderOptions={subHeaderOptions}
      />
      <Main $hasSubHeader={!!subHeaderOptions}>
        <Assistant
          data-testid={stepId}
          title={topic}
          emoji="indexFingerUp"
          twoColumnLayoutSupport={twoColumnLayoutSupport}
          leadText={primaryHeadingLeadText}
          fullViewportHeight={fullViewportHeight}
        >
          <TightSectionContent>
            {shouldUsePaperContainer ? (
              <PaperContainerWrapper>
                <PaperContainer $largeScreenSupport={true}>
                  <LargeScreenContainer>
                    {secondaryHeadingHeader && (
                      <SecondaryHeading header={secondaryHeadingHeader} leadText={leadTexts} />
                    )}
                    <StyledTightSectionContent>{children}</StyledTightSectionContent>
                  </LargeScreenContainer>
                </PaperContainer>
              </PaperContainerWrapper>
            ) : (
              <div>{children}</div>
            )}
            {notification && (
              <NotificationWrapper>
                <InfoIcon style={{ color: Colors.ElementInformation }} />
                <NotificationArea>
                  <BodyText>{notification}</BodyText>
                </NotificationArea>
              </NotificationWrapper>
            )}
          </TightSectionContent>
        </Assistant>
      </Main>
    </Wrapper>
  )
}
