import { EmojiType, GetEmoji } from '@ui-components/assets/Emojis'
import { ExtraTightSectionContent } from '@ui-components/containers/ExtraTightSectionContent'
import { SPACE_XXXSMALL } from '@ui-components/global/spacing'
import { AdditionalText, LeadText } from '@ui-components/typography/Typography'
import { ReactNode } from 'react'
import { styled } from 'styled-components'

import { Colors } from '../../global/colors'
import { MainHeading } from './headingPrimitives'

export interface PrimaryHeadingProps {
  header: string
  id?: string
  additionalText?: ReactNode
  leadText?: ReactNode
  tagName?: 'h1' | 'h2'
  emoji?: EmojiType
  isInfoHeading?: boolean
  className?: string
}

const Heading = styled(MainHeading)`
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphenate-limit-chars: 10 5 5;
  -ms-hyphenate-limit-chars: 10 5 5;
  -moz-hyphenate-limit-chars: 10 5 5;
  -webkit-hyphenate-limit-chars: 10 5 5;
`

const LeadTextWithMargin = styled(LeadText)`
  margin-top: ${SPACE_XXXSMALL};
`

const AdditionalTextWithMargin = styled(AdditionalText)<{ $color?: string }>`
  margin-top: ${SPACE_XXXSMALL};
  color: ${({ $color }) => $color};
`

const EmojiWrapper = styled.div<{ $color: string }>`
  color: ${({ $color }) => $color};
`

export const PrimaryHeading = ({
  header,
  additionalText,
  leadText,
  tagName = 'h1',
  id,
  className,
  emoji,
  isInfoHeading = false,
}: PrimaryHeadingProps) => {
  const AdditionalTextComponent = leadText ? AdditionalTextWithMargin : AdditionalText
  const color = isInfoHeading ? Colors.TextStrongestPrimary : Colors.TextMediumPrimary
  return (
    <ExtraTightSectionContent className={className}>
      {emoji && <EmojiWrapper $color={color}>{GetEmoji(emoji)}</EmojiWrapper>}
      <Heading tagName={tagName} id={id ?? 'main-heading'} color={color}>
        {header}
      </Heading>
      {leadText && <LeadTextWithMargin>{leadText}</LeadTextWithMargin>}
      {additionalText && (
        <AdditionalTextComponent $color={isInfoHeading ? color : undefined}>{additionalText}</AdditionalTextComponent>
      )}
    </ExtraTightSectionContent>
  )
}
