import { ChangeEventHandler, FocusEventHandler, HTMLAttributes, KeyboardEventHandler, ReactNode } from 'react'

// Not a full Autocomplete typing, but its what we have need for.
export type AutoComplete =
  | 'off'
  | 'on'
  | 'name'
  | 'given-name'
  | 'family-name'
  | 'email'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'one-time-code'
  | 'country'
  | 'country-name'
  | 'street-address'
  | 'address-line1'
  | 'address-line2'
  | 'address-line3'
  | 'address-line4'
  | 'postal-code'
  | 'transaction-currency'
  | 'transaction-amount'
  | 'language'
  | 'bday'
  | 'bday-day'
  | 'bday-month'
  | 'bday-year'
  | 'sex'
  | 'tel'

export interface FontTheme {
  fontFamily?: string
  fontSize?: string
  fontWeight?: number
  lineHeight?: string
  fontColor?: string
  backgroundColor?: string
}

export type TextBadgeColors = 'primary' | 'neutral' | 'info' | 'success' | 'warning' | 'error' | undefined

export function setFontThemeCss($theme: FontTheme | undefined): string {
  if ($theme) {
    const cssStyle: string[] = []
    if ($theme.fontFamily) {
      cssStyle.push('font-family:' + $theme.fontFamily)
    }

    if ($theme.fontSize) {
      cssStyle.push('font-size:' + $theme.fontSize)
    }

    if ($theme.fontWeight) {
      cssStyle.push('font-weight:' + $theme.fontWeight)
    }

    if ($theme.lineHeight) {
      cssStyle.push('line-height:' + $theme.lineHeight)
    }
    if ($theme.fontColor) {
      cssStyle.push('color:' + $theme.fontColor)
    }
    if ($theme.backgroundColor) {
      cssStyle.push('background-color:' + $theme.backgroundColor)
    }

    return cssStyle.join(';')
  }

  return ''
}

export interface ThemedComponentProps extends SideProp {
  $theme?: FontTheme
  theme?: FontTheme
}
export interface SideProp {
  side?: 'left' | 'right'
}
export type TextInputMode = 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'

export interface InputFieldProps extends ThemedComponentProps {
  defaultValue?: string
  name: string
  id?: string
  type?: string
  inputMode?: TextInputMode
  placeholder?: string
  required?: boolean
  maxLength?: number | null
  onBlur?: FocusEventHandler
  onChange?: ChangeEventHandler
  onKeyPress?: KeyboardEventHandler
  onKeyDown?: KeyboardEventHandler
  className?: string
  tabIndex?: number
  disabled?: boolean
  readOnly?: boolean
  error?: string
  autoComplete?: AutoComplete
  children?: ReactNode
  description?: string | null
}

export interface HTMLAttributesWithId extends HTMLAttributes<{ id: string }> {
  id: string
}

export interface CheckboxProps extends InputFieldProps {
  optionsFormId?: string
  color?: string
  value?: string
}

export interface FormRadioFieldProps
  extends Omit<InputFieldProps, 'description'>,
    SideProp,
    BorderedComponentProps,
    LabelledComponentProps {
  optionsFormId?: string
  color?: string
  value: string
}

export interface FormLabelProps extends InputFieldProps, SideProp {
  width?: string
}

export interface FormGroupOption {
  key: string
  label: string
  description?: string
}

export interface FormGroupProps
  extends FormLabelProps,
    SideProp,
    BorderedComponentProps,
    InputWithValidation,
    LabelledComponentProps {
  options: FormGroupOption[]
  color?: string
  optionsFormId?: string
  optionsDisabledStates?: boolean[]
  additionalDescriptionForFieldset?: ReactNode
  additionalDescriptionForFieldsetIndented?: boolean
  children?: ReactNode
}
export interface FormTextInputProps extends FormLabelProps, InputWithValidation, LabelledComponentProps {
  renderPrefix?: (props: HTMLAttributes<{ id: string }>) => JSX.Element
  additionalDescription?: string
  children?: ReactNode
}

export interface FormSelectInputProps extends FormTextInputProps, InputWithValidation {
  additionalElement?: ReactNode
}

export interface InputWithValidation {
  error?: string
}

export interface BorderedComponentProps {
  border?: boolean
}

export interface LabelledComponentProps {
  label?: string
  description?: string | null
}

export interface FormLabelledCheckBoxProps
  extends LabelledComponentProps,
    InputWithValidation,
    CheckboxProps,
    SideProp,
    BorderedComponentProps {
  $shouldCenterItemsVertically?: boolean
  $isGrouped?: boolean
  $isOneColumnLayout?: boolean
  isOneColumnLayout?: boolean
  groupId?: string
  id: string
}

export interface FormDescribedCheckboxProps
  extends InputWithValidation,
    CheckboxProps,
    SideProp,
    BorderedComponentProps {
  id?: string
  description: string
  additionalDescription?: string
  checkboxLabel: string
  isOneColumnLayout?: boolean
}

export interface ProgressBarStepProps {
  currentStepIndex: number
  totalSteps: number
  'aria-label': string
}

export interface SubHeaderProps {
  stepTitle?: string
  stepDescription?: string
  backButtonLabel?: string
  onBack?: () => unknown
  infoButtonLabel?: string
  onInfo?: () => unknown
}

export enum LogoIconType {
  SPankki = 'spankki',
  SOK = 'sok',
}
