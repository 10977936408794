import { assertValue } from '@shared'
import { FlexColumnContainer } from '@ui-components/global/CommonStyling'
import { StandardShadow } from '@ui-components/global/shadow'
import { QuaternaryHeading } from '@ui-components/layout'
import { AdditionalText, BodyText } from '@ui-components/typography/Typography'
import { useId, useState } from 'react'
import { styled } from 'styled-components'

import { Button, ButtonProps } from '../actions/Button'
import { Link } from '../actions/Link'
import { LinkButton, LinkButtonProps } from '../actions/LinkButton'
import { CloseIcon, IconSize, InfoIcon, KebabMenuIcon, LeftChevronIcon } from '../assets/Icons'
import { Colors } from '../global/colors'
import { BREAKPOINT_DESKTOP_PX, STEP_HEADER_HEIGHT, STEP_HEADER_SMALL_HEIGHT } from '../global/constants'
import { SPACE_SMALL, SPACE_XXSMALL } from '../global/spacing'
import { useMediaQuery } from '../global/useMediaQuery'
import { PAGE_BACK_BUTTON_CLASS, PAGE_TITLE_CLASS } from '../utilities/helpers'

const TopBarContainer = styled(FlexColumnContainer)<{ $hideBoxShadow?: boolean }>`
  ${(props) => (props.$hideBoxShadow ? undefined : StandardShadow)}
`

const TopBarWrapper = styled.div<{ $small?: boolean }>`
  background-color: ${Colors.White};
  width: 100%;
  min-height: ${({ $small }) => ($small ? `${STEP_HEADER_SMALL_HEIGHT}px` : `${STEP_HEADER_HEIGHT}px`)};
  display: grid;
  align-items: center;
  grid-template-columns: 36px 1fr 36px;

  @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
    grid-template-columns: 200px 1fr 200px;
  }

  svg {
    padding-right: 0;
    height: ${IconSize.SMALL}px;
    box-sizing: content-box;
  }
`

const ItemWrapper = styled.div<{ $noPointerEvents?: boolean; $removeSidePadding?: boolean }>`
  text-align: center;

  h1 {
    margin-top: 0;
  }

  > * {
    pointer-events: ${({ $noPointerEvents }) => $noPointerEvents && `none`};
  }

  &:first-child {
    justify-self: start;
    margin-left: ${({ $removeSidePadding }) => ($removeSidePadding ? 0 : SPACE_SMALL)};
    :hover {
      color: ${Colors.TextDefaultNeutral};
    }
    :active {
      color: ${Colors.TextDefaultNeutral};
    }
  }

  &:nth-child(2) {
    padding: 0 ${SPACE_XXSMALL};
  }

  &:last-child {
    justify-self: end;
    margin-right: ${({ $removeSidePadding }) => ($removeSidePadding ? 0 : SPACE_SMALL)};
  }
`

const MenuContent = styled(FlexColumnContainer)`
  background-color: ${Colors.White};
  border-top: 1px solid ${Colors.BorderWeakNeutral};
  padding: 16px 0;
  border-bottom: 1px solid ${Colors.BorderWeakNeutral};
`

const MenuOption = styled(FlexColumnContainer)`
  align-items: center;

  a {
    width: fit-content;
    padding: 0 16px;
    min-height: 40px;
  }
`

const MenuToggleWrapper = styled.div`
  cursor: pointer;
  min-width: 40px;
  min-height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  svg {
    padding: 0;
  }
`

export type SecondaryTopBarMenuOption = {
  text: string
  textColor?: string
  onClick: () => unknown
}

export interface SecondaryTopBarMenuProps {
  menuAriaLabelOpen?: string
  menuAriaLabelClose?: string
  menuOptions?: SecondaryTopBarMenuOption[]
}

interface ToggleMenuProps extends Omit<SecondaryTopBarMenuProps, 'menuOptions'> {
  isMenuOpen: boolean
  setIsMenuOpen: (open: boolean) => void
  'aria-controls': string
}

type InfoButtonProps = Pick<ButtonProps, 'label' | 'onClick'>

export interface SecondaryTopBarProps {
  title?: string
  description?: string
  previousButtonProps?: Pick<LinkButtonProps, 'label' | 'onClick'>
  infoButtonProps?: InfoButtonProps
  small?: boolean
  menu?: SecondaryTopBarMenuProps
  hideBoxShadow?: boolean
  hideButtonLabelsInMobileBreakpoint?: boolean
  removeSidePadding?: boolean
  titleTagName?: 'h1' | 'h2'
}

export const SecondaryTopBar = ({
  previousButtonProps,
  infoButtonProps,
  title,
  small,
  description,
  menu = {},
  hideBoxShadow,
  hideButtonLabelsInMobileBreakpoint = true,
  removeSidePadding = false,
  titleTagName = 'h1',
}: SecondaryTopBarProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const toggleMenuId = `toggle-menu-${useId()}`
  const { menuOptions, menuAriaLabelOpen, menuAriaLabelClose } = menu

  const { label: previousButtonLabel, onClick: previousButtonOnClick } = previousButtonProps ?? {}
  const { label: infoButtonLabel, onClick: infoButtonOnClick } = infoButtonProps ?? {}

  const isDesktopSize = useMediaQuery(`(min-width:${BREAKPOINT_DESKTOP_PX}px)`)

  const showButtonLabels = !hideButtonLabelsInMobileBreakpoint || isDesktopSize

  const handlePreviousButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // Prevent default navigation behaviour
    e.preventDefault()
    previousButtonOnClick?.(e)
  }

  return (
    <TopBarContainer $hideBoxShadow={hideBoxShadow}>
      <TopBarWrapper $small={small}>
        <ItemWrapper $removeSidePadding={removeSidePadding}>
          {previousButtonOnClick && (
            <LinkButton
              aria-label={assertValue(previousButtonLabel, 'previousButtonLabel')}
              className={PAGE_BACK_BUTTON_CLASS}
              color="neutral"
              compact={true}
              data-testid="step-header-previous-button"
              icon={<LeftChevronIcon />}
              label={showButtonLabels ? previousButtonLabel : undefined}
              onClick={handlePreviousButtonClick}
              variant="plain"
            />
          )}
        </ItemWrapper>
        <ItemWrapper aria-live="polite" $noPointerEvents={true}>
          {title && (
            <QuaternaryHeading className={PAGE_TITLE_CLASS} header={title} tagName={titleTagName} tabIndex={-1} />
          )}
          {description && <AdditionalText>{description}</AdditionalText>}
        </ItemWrapper>
        <ItemWrapper $removeSidePadding={removeSidePadding}>
          {menuOptions && (
            <ToggleMenu
              aria-controls={toggleMenuId}
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              menuAriaLabelOpen={menuAriaLabelOpen}
              menuAriaLabelClose={menuAriaLabelClose}
            />
          )}
          {infoButtonOnClick && (
            <InfoButton label={infoButtonLabel} onClick={infoButtonOnClick} showButtonLabel={showButtonLabels} />
          )}
        </ItemWrapper>
      </TopBarWrapper>
      {isMenuOpen && (
        <MenuContent id={toggleMenuId}>
          {menuOptions?.map((menuOption) => (
            <MenuOption key={menuOption.text}>
              <Link standalone={true} onClick={menuOption.onClick}>
                <BodyText>{menuOption.text}</BodyText>
              </Link>
            </MenuOption>
          ))}
        </MenuContent>
      )}
    </TopBarContainer>
  )
}

const ToggleMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  'aria-controls': ariaControls,
  menuAriaLabelOpen,
  menuAriaLabelClose,
}: ToggleMenuProps) => {
  const onToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <MenuToggleWrapper>
      <Button
        aria-label={isMenuOpen ? menuAriaLabelClose : menuAriaLabelOpen}
        aria-expanded={isMenuOpen}
        aria-controls={ariaControls}
        compact={true}
        color="neutral"
        icon={isMenuOpen ? <CloseIcon /> : <KebabMenuIcon />}
        onClick={onToggle}
        variant="plain"
      />
    </MenuToggleWrapper>
  )
}

const InfoButton = ({ label, onClick, showButtonLabel }: InfoButtonProps & { showButtonLabel: boolean }) => {
  return (
    <Button
      aria-label={!showButtonLabel ? label : undefined}
      color="neutral"
      compact={true}
      data-testid="step-header-info-button"
      icon={<InfoIcon style={{ color: Colors.ElementDefaultNeutral }} />}
      label={showButtonLabel ? label : undefined}
      onClick={onClick}
      variant="plain"
    />
  )
}
