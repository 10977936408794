import { CardIconType } from '@ui-components/containers/Card'
import { forwardRef, ReactElement } from 'react'

import { EmojiType } from '../assets/Emojis'
import { ComplexMessage } from '../common/types'
import { Assistant } from './Assistant'
import { SimplePhasesView, SimplePhasesViewProps } from './SimplePhasesView'

export type PhaseInstructions = Record<CompletionProgress, PhaseHeaderTexts>

export interface PhasesViewProps extends SimplePhasesViewProps {
  instructions: PhaseInstructions
  fullViewportHeight?: boolean
}

export interface PhaseHeaderTexts {
  title: string
  text: string
  additionalText?: string
}

export interface PhaseContent {
  icon?: CardIconType
  description?: string
  text: ComplexMessage
  action?: {
    kind?: 'button' | 'content'
    label?: string
    onClick: () => unknown
    dataTestId?: string
  }
}

export interface Phase {
  header: string
  content?: PhaseContent | ReactElement
  isComplete: boolean
}

export enum CompletionProgress {
  NotStarted = 'initial',
  InComplete = 'started',
  InCompletePastHalf = 'pastHalfway',
  Complete = 'complete',
}

const completionProgressEmojis: Record<CompletionProgress, EmojiType> = {
  [CompletionProgress.NotStarted]: 'heyThere',
  [CompletionProgress.InComplete]: 'thumbUp',
  [CompletionProgress.InCompletePastHalf]: 'raisedHands',
  [CompletionProgress.Complete]: 'heart',
}

const getEmoji = (progress: CompletionProgress): EmojiType => {
  return completionProgressEmojis[progress]
}

export const PhasesView = forwardRef<HTMLDivElement, PhasesViewProps>(function PhasesView(props: PhasesViewProps, ref) {
  let progress: CompletionProgress = CompletionProgress.NotStarted

  const { phases, instructions, phasesHeader, shouldShowCompletedPhases, fullViewportHeight } = props

  const completePhaseCount = phases.filter((phase) => phase.isComplete).length
  const inCompletePhaseCount = phases.length - completePhaseCount

  if (phases.every((phase) => phase.isComplete) || inCompletePhaseCount === 1) {
    progress = CompletionProgress.Complete
  } else if (completePhaseCount >= inCompletePhaseCount) {
    progress = CompletionProgress.InCompletePastHalf
  } else if (phases.some((phase) => !phase.isComplete) && completePhaseCount > 0) {
    progress = CompletionProgress.InComplete
  }

  const guidance = instructions[progress]

  return (
    <Assistant
      title={guidance.title}
      leadText={guidance.text}
      additionalText={guidance.additionalText}
      emoji={getEmoji(progress)}
      ref={ref}
      fullViewportHeight={fullViewportHeight}
    >
      <SimplePhasesView
        phasesHeader={phasesHeader}
        phases={phases}
        shouldShowCompletedPhases={shouldShowCompletedPhases}
      />
    </Assistant>
  )
})
