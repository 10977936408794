import { BodyText } from '@ui-components/typography/Typography'
import { ChangeEvent, DragEvent, useRef, useState } from 'react'
import { styled } from 'styled-components'

import { Button } from '../actions/Button'
import { Label } from '../dataDisplay/Label'
import { Colors } from '../global/colors'
import { SPACE_XXXSMALL } from '../global/spacing'

const DropzoneContainer = styled.div<{ $isDraggedOver: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: ${Colors.BorderStrongNeutral} 1px ${(props) => (props.$isDraggedOver ? 'solid' : 'dashed')};
  border-radius: ${SPACE_XXXSMALL};
  background-color: ${(props) =>
    props.$isDraggedOver ? `${Colors.BackgroundWeakNeutral}` : `${Colors.BackgroundWeakestNeutral}`};
  padding: 24px 0;
`

const UploadButtonDescription = styled(BodyText)<{ $isDanger: boolean }>`
  margin-top: ${SPACE_XXXSMALL};
  text-align: center;
  color: ${(props) => (props.$isDanger ? Colors.TextError : Colors.TextDefaultNeutral)};
`

const StyledLabel = styled(Label)`
  > div {
    margin-bottom: ${SPACE_XXXSMALL};
  }
`

interface FormUploadProps {
  onDrop: (event: DragEvent<HTMLDivElement>) => void
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  uploadButtonTitle: string
  uploadButtonDescription: string
  dropzoneTitle: string
  dropzoneDescription?: string
  accept: string | undefined
  isDanger?: boolean
  isDisabled?: boolean
  uploadButtonAriaLabel: string
  isFileLimitReached?: boolean
  onFileLimitReached?: () => void
}
export const FormUpload = ({
  onDrop,
  onChange,
  uploadButtonTitle,
  uploadButtonDescription,
  dropzoneTitle,
  dropzoneDescription,
  accept,
  isDanger = false,
  isDisabled = false,
  uploadButtonAriaLabel,
  isFileLimitReached,
  onFileLimitReached,
}: FormUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const dropzoneRef = useRef<HTMLDivElement>(null)
  const [isDraggedOver, setIsDraggedOver] = useState(false)

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    // Prevent file from being opened when dragged in
    event.preventDefault()
  }

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    const relatedTarget = event.relatedTarget as HTMLElement | null
    const parentDiv = event.currentTarget

    if (!parentDiv.contains(relatedTarget)) {
      setIsDraggedOver(false)
    }
  }

  return (
    <>
      <StyledLabel id={'form-upload'} label={dropzoneTitle} description={dropzoneDescription} />
      <DropzoneContainer
        id="drop-zone"
        data-testid="drop-zone"
        onDrop={(e) => {
          if (isFileLimitReached) {
            onFileLimitReached?.()
            return
          }
          onDrop(e)
          setIsDraggedOver(false)
        }}
        $isDraggedOver={isDraggedOver}
        ref={dropzoneRef}
        onDragOver={onDragOver}
        onDragEnter={() => setIsDraggedOver(true)}
        onDragLeave={onDragLeave}
      >
        <input
          type="file"
          id="files"
          data-testid="files"
          hidden={true}
          multiple={true}
          onChange={onChange}
          disabled={isDisabled}
          ref={inputRef}
          accept={accept}
          value=""
        />
        <Button
          onClick={(e) => {
            if (e.currentTarget.ariaDisabled === 'true') {
              return
            }
            if (isFileLimitReached) {
              onFileLimitReached?.()
              return
            }
            if (inputRef?.current) {
              inputRef.current.click()
            }
          }}
          aria-label={uploadButtonAriaLabel}
          aria-disabled={isDisabled}
          disabled={isDisabled}
          aria-labelledby="upload-button-hidden-label"
          aria-describedby="upload-button-description"
          label={uploadButtonTitle}
          variant="secondary"
        />
        <UploadButtonDescription
          weight="regular"
          tagName="span"
          $isDanger={isDanger}
          id={'upload-button-description'}
          data-testid="upload-button-description"
        >
          {uploadButtonDescription}
        </UploadButtonDescription>
      </DropzoneContainer>
    </>
  )
}
