import { QuaternaryHeading, QuaternaryHeadingProps } from '@ui-components/typography/headings/QuaternaryHeading'
import { styled } from 'styled-components'

import { Colors } from '../global/colors'
import { SPACE_XXXSMALL } from '../global/spacing'

const StyledModalHeading = styled(QuaternaryHeading)`
  margin-top: 24px;
  margin-bottom: 8px;
`

export const ModalSubHeading = (props: QuaternaryHeadingProps) => {
  return <StyledModalHeading {...props} tagName="h3" />
}

export const ModalHeaderContainer = styled.div`
  gap: ${SPACE_XXXSMALL};
  span {
    color: ${Colors.TextStrongNeutral};
  }
`
