import { ExtraTightSectionContent } from '@ui-components/containers/ExtraTightSectionContent'
import { TightSectionContent } from '@ui-components/containers/TightSectionContent'
import { AdditionalText, LeadText } from '@ui-components/typography/Typography'
import { ReactNode } from 'react'

import { LargeHeading } from './headingPrimitives'

export interface SecondaryHeadingProps {
  header: string
  id?: string
  tagName?: 'h2' | 'h3'
  additionalText?: ReactNode | string
  leadText?: ReactNode | string
}

export const SecondaryHeading = ({ header, id, tagName = 'h2', additionalText, leadText }: SecondaryHeadingProps) => {
  const Wrapper = leadText ? TightSectionContent : ExtraTightSectionContent
  return (
    <Wrapper>
      <LargeHeading tagName={tagName} id={id}>
        {header}
      </LargeHeading>
      {leadText && <LeadText>{leadText}</LeadText>}
      {additionalText && <AdditionalText>{additionalText}</AdditionalText>}
    </Wrapper>
  )
}
