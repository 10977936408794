import { PropsWithChildren } from 'react'
import { styled } from 'styled-components'

import { PAGE_COMPONENT_GAP } from '../global/constants'
import { BaseModal } from './BaseModal'
import { ModalContent } from './ModalContent'
import { ModalVisualMode } from './ModalTypes'
import { getIcon } from './ModalUtils'

export interface NoButtonsModalProps {
  contentLabel?: string
  onClose: () => unknown
  isOpen: boolean
  header?: string
  requestId?: string
  mode?: ModalVisualMode
}

const Content = styled(ModalContent)`
  padding-bottom: ${PAGE_COMPONENT_GAP}px;
`

export const NoButtonsModal = ({
  contentLabel,
  onClose,
  isOpen,
  header,
  children,
  requestId,
  mode = ModalVisualMode.Plain,
}: PropsWithChildren<NoButtonsModalProps>) => {
  const icon = getIcon(mode)
  return (
    <BaseModal contentLabel={contentLabel ?? header ?? 'Modal'} onClose={onClose} isOpen={isOpen} footer={undefined}>
      <Content header={header} requestId={requestId} icon={icon}>
        {children}
      </Content>
    </BaseModal>
  )
}
