import {
  ButtonSection,
  FullWidthContentSection,
  Section,
  SimplePageContentSection,
} from '@ui-components/global/LayoutStyling'
import { useLayoutEffect } from 'react'
import { styled } from 'styled-components'

import { PrimaryButton } from '../actions/special/DynamicWidthButtons'
import { PaddedDivider } from '../decorative/PaddedDivider'
import { Colors } from '../global/colors'
import { LogoIconType } from '../global/ComponentProps'
import { ButtonContainer } from '../layout/ButtonContainer'
import { PrimaryTopBar, PrimaryTopBarProps } from '../navigation/PrimaryTopBar'
import { ConfirmButtonProps } from './WizardTypes'

export interface SimpleStepProps extends PrimaryTopBarProps {
  testId?: string
  confirmButtonProps?: ConfirmButtonProps
  children?: React.ReactNode
  isInfoBackground?: boolean
  largeScreenSupport?: boolean
  transparentTopBar?: boolean
}

const ConfirmButtonSection = styled(ButtonSection)`
  padding-top: 16px;
`

const BottomSection = styled(Section)`
  width: 100%;
  padding: 0px;
`

export const SimpleStep = (props: SimpleStepProps): JSX.Element => {
  const defaults = { icon: props.icon ?? LogoIconType.SPankki }
  const { confirmButtonProps, isInfoBackground, largeScreenSupport = false } = props

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <PrimaryTopBar {...props} {...defaults} isTransparent={props.transparentTopBar} />
      <FullWidthContentSection $isInfoSection={isInfoBackground}>
        <SimplePageContentSection
          $backgroundColor={Colors.BackgroundWeakNeutral}
          $largeScreenSupport={largeScreenSupport}
          data-testid={props.testId}
        >
          {props.children}
          {confirmButtonProps && (
            <BottomSection>
              <PaddedDivider />
              <ConfirmButtonSection>
                <ButtonContainer>
                  <PrimaryButton
                    label={confirmButtonProps.confirmLabel}
                    onClick={confirmButtonProps.onConfirm}
                    data-testid="confirm-page-button"
                    disabled={confirmButtonProps.disabled}
                  />
                </ButtonContainer>
              </ConfirmButtonSection>
            </BottomSection>
          )}
        </SimplePageContentSection>
      </FullWidthContentSection>
    </>
  )
}
