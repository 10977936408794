import { Card as SdsCard } from '@s-group/design-system-components/Card/Card'
import { Link as SdsLink } from '@s-group/design-system-components/Link/Link'
import { QuaternaryHeading } from '@ui-components/layout'
import { ReactElement, ReactNode } from 'react'
import { styled } from 'styled-components'

import { PrimaryButton } from '../actions/special/DynamicWidthButtons'
import {
  BankingCredentialsIcon,
  CommunicationMessageUnreadIcon,
  HospitalityDiamondIcon,
  IconSize,
  NavigationChevronRightIcon,
  PaymentSBonusVerticalIcon,
  PaymentWalletIcon,
  SokGreenIcon,
  UserPasswordIcon,
  UserProfileIcon,
} from '../assets/Icons'
import { ColoredLabelProps } from '../dataDisplay/ColoredLabel'
import { Divider } from '../decorative/Divider'
import { Colors } from '../global/colors'
import { BREAKPOINT_DESKTOP_PX, FONT_FAMILY_S_BONUS_UX } from '../global/constants'
import { SPACE_SMALL, SPACE_XXSMALL } from '../global/spacing'
import { resolveCssDimensionValue } from '../utilities/helpers'

export type CardProps = BasicCardProps | PrimaryCardProps | RichCardProps

export interface BasicCardProps extends BaseCardProps {
  variant: 'basic'
}

export interface PrimaryCardProps extends BaseCardProps {
  variant: 'primary'
  clickOptions: ClickOptions
}

export interface RichCardProps extends BaseCardProps {
  variant: 'rich'
  shortcutElements: ReactElement[]
  clickOptions?: ClickOptions
}

type CardTagName = keyof Pick<JSX.IntrinsicElements, 'article' | 'section' | 'li'>

export interface BaseCardProps {
  title: CardTitle
  subtitle?: CardSubtitle
  description?: ReactNode
  icon?: CardIconType
  textBadge?: React.ReactElement<ColoredLabelProps>
  className?: string
  maxWidth?: number | string
  tagName?: CardTagName
  ['aria-labelledby']?: string
  ['data-testid']?: string
}

interface Title {
  text: string
  id?: string
}

export interface CardTitle extends Title {
  $variant?: 'body' | 'heading'
  $tagName?: 'h2' | 'h3'
}

export type CardSubtitle = Title

export type Variant = 'basic' | 'primary' | 'rich'

export type ClickOptions = WithButtonClick | WithContentClick

interface WithClick {
  kind: 'button' | 'content'
  onClick: UnknownClickHandler | undefined
  ariaDescribedBy?: string
}

export interface WithButtonClick extends WithClick {
  kind: 'button'
  buttonText: string
  disabled?: boolean
  dataTestId?: string
}

export interface WithContentClick extends WithClick {
  kind: 'content'
}

export type ClickOptionKind = 'button' | 'content'

export type UnknownClickHandler = () => void

const BaseCard = styled(SdsCard)<{
  $cardVariant: Variant
  $maxWidth: number | string | undefined
}>`
  font-family: ${FONT_FAMILY_S_BONUS_UX};
  max-width: ${({ $maxWidth }) => resolveCssDimensionValue($maxWidth, '500px')};
  min-width: ${(props) => (props.$cardVariant === 'basic' ? 'auto' : '242px')};
  && {
    &:hover {
      box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.16);
    }
  }
  container-type: inline-size;
  color: ${Colors.TextDefaultNeutral};
`

const Content = styled.div<{ $variant: Variant; $cursorPointer: boolean }>`
  position: relative;
  display: flex;
  flex-wrap: ${(props) => (props.$variant === 'basic' ? 'wrap' : 'nowrap')};
  justify-content: space-between;
  gap: ${SPACE_SMALL};
  padding: ${SPACE_SMALL};
  cursor: ${(props) => (props.$cursorPointer ? 'pointer' : 'auto')};
`

export const SHOW_ICON_BREAKPOINT = 343

const IconWrapper = styled.div.attrs<{ $variant: Variant }>({ 'aria-hidden': true })`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${Colors.BackgroundWeakPrimary};
  color: ${Colors.ElementStrongPrimary};
  @container (max-width: ${SHOW_ICON_BREAKPOINT}px) {
    display: ${(props) => (props.$variant === 'basic' ? 'flex' : 'none')};
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
`

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  text-align: left;
`

const TextBadgeWrapper = styled.section`
  span {
    font-weight: 400;
  }
`

const Description = styled.div`
  margin-top: ${SPACE_XXSMALL};
`

const ButtonWrapper = styled.div``

const ChevronRightIconWrapper = styled.div.attrs({ 'aria-hidden': true })`
  display: flex;
  align-items: center;
  height: 60px;
`

const AccessibilityHelper = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

const Shortcuts = styled.footer``

const ShortcutElementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${SPACE_SMALL} ${SPACE_SMALL};
`

const ShortcutElementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${SPACE_SMALL};
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 24px;
    }
  }
  button {
    @media (min-width: ${BREAKPOINT_DESKTOP_PX}px) {
      width: 100%;
    }
  }
`

export interface LinkProps {
  href: string
  icon: JSX.Element
  onClick?: UnknownClickHandler
  label: string
}

export type CardIconType =
  | 'sokIcon'
  | 'walletIcon'
  | 'sbonusVerticalIcon'
  | 'userProfileIcon'
  | 'userPasswordIcon'
  | 'bankingCredentialsIcon'
  | 'communicationMessageUnreadIcon'
  | 'hospitalityDiamondIcon'

const icons: Record<CardIconType, JSX.Element> = {
  sokIcon: <SokGreenIcon size={IconSize.MEDIUM} />,
  walletIcon: <PaymentWalletIcon size={IconSize.MEDIUM} />,
  sbonusVerticalIcon: <PaymentSBonusVerticalIcon size={IconSize.MEDIUM} />,
  userProfileIcon: <UserProfileIcon size={IconSize.MEDIUM} />,
  userPasswordIcon: <UserPasswordIcon size={IconSize.MEDIUM} />,
  bankingCredentialsIcon: <BankingCredentialsIcon size={IconSize.MEDIUM} />,
  communicationMessageUnreadIcon: <CommunicationMessageUnreadIcon size={IconSize.MEDIUM} />,
  hospitalityDiamondIcon: <HospitalityDiamondIcon size={IconSize.MEDIUM} />,
}

export const CardLink = ({ href, icon, label, onClick }: LinkProps): JSX.Element => (
  <SdsLink href={href} standalone={true} icon={icon} onClick={onClick}>
    {label}
  </SdsLink>
)

export const Card = (props: CardProps): JSX.Element => {
  const {
    className,
    variant,
    title,
    subtitle,
    description,
    icon,
    textBadge,
    ['data-testid']: dataTestId,
    ['aria-labelledby']: ariaLabelledBy,
    maxWidth,
    tagName,
  } = props

  const eventfulContent = variant !== 'basic' && props.clickOptions

  const getContentClickHandler = (): UnknownClickHandler | undefined => {
    if (variant === 'basic') {
      return undefined
    }

    const { clickOptions } = props

    if (!clickOptions || clickOptions.kind === 'button') {
      return undefined
    }

    return clickOptions.onClick
  }

  const contentClickHandler = getContentClickHandler()

  return (
    <BaseCard
      tagName={tagName || 'section'}
      data-testid={dataTestId}
      className={className}
      variant="elevated"
      rounding="rounded"
      aria-labelledby={ariaLabelledBy}
      $cardVariant={variant}
      $maxWidth={maxWidth}
    >
      <Content $cursorPointer={!!contentClickHandler} $variant={variant}>
        {icon && (
          <IconWrapper $variant={variant}>
            <IconContainer>{icons[icon]}</IconContainer>
          </IconWrapper>
        )}
        <MainContent>
          <QuaternaryHeading
            header={title.text}
            id={title.id}
            weight="medium"
            tagName={title.$tagName}
            additionalText={subtitle?.text}
            additionalTextId={subtitle?.id}
          />
          {description && <Description>{description}</Description>}
        </MainContent>
        {textBadge && <TextBadgeWrapper>{textBadge}</TextBadgeWrapper>}
        {eventfulContent &&
          (props.clickOptions?.kind === 'button' ? (
            <ButtonWrapper>
              <PrimaryButton
                $isFullWidth={true}
                onClick={props.clickOptions.onClick}
                disabled={props.clickOptions.disabled}
                data-testid={props.clickOptions.dataTestId}
                label={props.clickOptions.buttonText}
                aria-describedby={props.clickOptions.ariaDescribedBy}
              />
            </ButtonWrapper>
          ) : (
            <>
              <ChevronRightIconWrapper>
                <NavigationChevronRightIcon />
              </ChevronRightIconWrapper>
              {contentClickHandler && (
                <AccessibilityHelper
                  href="#"
                  aria-label={title.text}
                  aria-describedby={props.clickOptions?.ariaDescribedBy}
                  onClick={(e) => {
                    e.preventDefault()
                    contentClickHandler()
                  }}
                />
              )}
            </>
          ))}
      </Content>
      {variant === 'rich' && (
        <Shortcuts>
          <Divider />
          <ShortcutElementsWrapper>
            {props.shortcutElements.map((el) => (
              <ShortcutElementWrapper key={el.key}>{el}</ShortcutElementWrapper>
            ))}
          </ShortcutElementsWrapper>
        </Shortcuts>
      )}
    </BaseCard>
  )
}
