import { Divider as DSDivider } from '@s-group/design-system-components/Divider/Divider'
export type DividerSpacing =
  | 'xxxxsmall'
  | 'xxxsmall'
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'
  | 'xxxxlarge'
  | undefined

export const Divider = ({
  spacing,
  className,
  'data-testid': dataTestId,
  id,
}: {
  spacing?: DividerSpacing
  className?: string
  ['data-testid']?: string
  id?: string
}) => <DSDivider decorative={true} spacing={spacing} className={className} data-testid={dataTestId} id={id} />
